import { Button, Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Feedback, Loading, Message } from '../../../../components';
import useStores from '../../../../hooks/useStores';
import useStyles from './Result.styles';

const Result = () => {
  const { exchangeStore } = useStores();
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const end = () => {
    exchangeStore.end();

    const url = exchangeStore.draft ? '/private/draft' : '/private/sent';
    history.push(url);
  };

  useEffect(() => {
    exchangeStore.save();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!exchangeStore.loaded) {
    return <Loading />;
  }

  if (exchangeStore.error) {
    return (
      <Box className={styles.container}>
        <Message logo="error" title="result.error.title" subtitle="result.error.subtitle" />
        <Button className={styles.action} variant="contained" color="secondary" onClick={() => exchangeStore.review()}>
          <Trans t={t} i18nKey="result.error.action" />
        </Button>
      </Box>
    );
  }

  if (exchangeStore.draft) {
    return (
      <Box className={styles.container}>
        <Message logo="drafts" title="exchanges.drafts.empty" subtitle="exchanges.drafts.hint" />
        <Button className={styles.action} variant="contained" color="secondary" onClick={end}>
          <Trans t={t} i18nKey="result.confirmed.action" />
        </Button>
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      <Message logo="confirmed" title="result.confirmed.title" subtitle="result.confirmed.subtitle" />
      <Button className={styles.action} variant="contained" color="secondary" onClick={end}>
        <Trans t={t} i18nKey="result.confirmed.action" />
      </Button>
      <Feedback id={exchangeStore.id} type="exchange" />
    </Box>
  );
};

export default observer(Result);
