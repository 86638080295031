import { Button, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStores from '../../hooks/useStores';
import useStyles from './LanguageSelector.styles';
import PopperMenu, { ProperMenuHandle } from '../popperMenu/PopperMenu';

interface LanguageSelectorProps {
  forScreen: 'public' | 'menu';
}

const LanguageSelector = ({ forScreen }: LanguageSelectorProps) => {
  const { t, i18n } = useTranslation();
  const { themeStore } = useStores();

  const styles = useStyles();
  const ref = useRef<ProperMenuHandle>(null);
  const options = [
    { key: 'languages.es', value: 'es' },
    { key: 'languages.en', value: 'en' },
    { key: 'languages.de', value: 'de' },
    { key: 'languages.nl', value: 'nl' }
  ];

  const handleOpen = () => {
    ref.current?.open();
  };

  const handleSelect = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const languageKey = () => {
    let language = 'en';
    let languages = ['es', 'de', 'nl'];
    languages.forEach((l) => {
      if (i18n.language?.startsWith(l)) {
        language = l;
      }
    });

    return `languages.${language}`;
  };

  const icon = () => {
    if (forScreen === 'public') {
      return `${process.env.PUBLIC_URL}/icons/${themeStore.theme?.language?.arrow.public}`;
    }

    return `${process.env.PUBLIC_URL}/icons/${themeStore.theme?.language?.arrow.menu}`;
  };

  return (
    <PopperMenu options={options} menuRef={ref} onSelected={handleSelect}>
      <Button className={styles.selected} onClick={handleOpen}>
        <Typography className={styles[forScreen]} variant="body2">
          <Trans t={t} i18nKey={languageKey()} />
        </Typography>
        <img alt="" src={icon()} onClick={handleOpen} />
      </Button>
    </PopperMenu>
  );
};

export default LanguageSelector;
