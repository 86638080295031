import { Theme } from '@material-ui/core';
import { makeAutoObservable } from 'mobx';
import { lightTheme, darkTheme } from '../themes';

class ThemeStore {
  private readonly _themeKey = 'theme';

  theme?: Theme;
  name?: 'dark' | 'light';

  constructor() {
    makeAutoObservable(this);
  }

  loadTheme() {
    let storedThemeKey = localStorage.getItem(this._themeKey);
    this.theme = storedThemeKey === 'dark' ? darkTheme : lightTheme;
    this.name = storedThemeKey === 'dark' ? 'dark' : 'light';
  }

  changeTheme() {
    let storedThemeKey = localStorage.getItem(this._themeKey);
    let newThemeKey = storedThemeKey === 'dark' ? 'light' : 'dark';
    localStorage.setItem(this._themeKey, newThemeKey);
    this.loadTheme();
  }
}
export default ThemeStore;
