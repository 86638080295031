import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { IDocument } from '../../domain/Document';
import useStyles from './PdfViewer.styles';

interface PdfViewerProps {
  document: IDocument;
}

const PdfViewer = ({ document }: PdfViewerProps) => {
  const styles = useStyles();
  const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const result = b64toBlob(document.content!, 'application/pdf');
  const resultUrl = URL.createObjectURL(result);

  return (
    <Box>
      <Box className={styles.header}>
        <img alt="" src={`${process.env.PUBLIC_URL}/icons/ic_pdf.svg`} />
        <Typography className={styles.filename} variant="h6">
          {document.filename}
        </Typography>
      </Box>
      <Box className={styles.content}>
        <object className={styles.file} id="pdf" data={resultUrl} type="application/pdf">
          <param name="zoom" value="FitH" />
          Loading PDF
        </object>
      </Box>
    </Box>
  );
};

export default PdfViewer;
