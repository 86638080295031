import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '2px dashed #79A6D2',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  filesContainer: {
    margin: theme.spacing(2),
  },
  drop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  action: {
    marginTop: theme.spacing(2),
    textTransform: 'none',
    fontWeight: 600
  },
  label: {
    marginBottom: theme.spacing(1)
  },
  text: {
    width: '640px',
    height: '32px',
    '& .Mui-error': {
      backgroundColor: '#FFF5F6'
    },
    "& .MuiFormHelperText-root": {
      backgroundColor: 'transparent',
      color: '#CC001B',
      marginTop: 0.5
    }
  },
  textDescription: {
    width: '100%'
  }
}));

export default useStyles;
