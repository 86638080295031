import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './NotaryListItem.styles';
import { Trans, useTranslation } from 'react-i18next';
import { INotary } from '../../../../domain/Notary';
import useStores from '../../../../hooks/useStores';
import { observer } from 'mobx-react';

interface NotaryListItemProps {
  onSelect: (selectedNotary: INotary) => void;
  current?: INotary[];
  notary: INotary;
}

const NotaryListItem = ({ onSelect, current, notary }: NotaryListItemProps) => {
  const styles = useStyles();
  const { themeStore } = useStores();
  const { t } = useTranslation();
  const isSeleted = current?.find((n) => n.directoryId === notary.directoryId) != null;

  return (
    <Box className={styles.container}>
      <Typography className={styles.name} variant="h4">
        {notary.name}
      </Typography>
      <Box className={styles.location}>
        <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/${themeStore.theme?.notary?.pin}`} alt="" />
        <Typography className={styles.locationInfo} variant="body2">
          {notary.address}, {notary.city}, {t(`countries.${notary.country}`)}
        </Typography>
      </Box>
      <Box className={styles.contact}>
        <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/ic_phone.svg`} alt="" />
        <Typography className={styles.phone} variant="body2">
          {notary.phone}
        </Typography>
        <Button
          disabled={isSeleted}
          className={styles.add}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onSelect(notary)}
        >
          <Trans t={t} i18nKey="sent.actions.add" />
        </Button>
      </Box>
    </Box>
  );
};

export default observer(NotaryListItem);
