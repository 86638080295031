import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Message } from '../../components';
import useStyles from './Error.styles';

const Unauthorized = () => {
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <img alt="" className={styles.logo} />
      <Box className={styles.box}>
        <Message logo="unauthorized" title="errors.title" subtitle="errors.unauthorized" />
        <Button onClick={() => history.push('/')} className={styles.accept} variant="contained" color="secondary">
          <Trans t={t} i18nKey="errors.back" />
        </Button>
        <Typography className={styles.contact} variant="body2">
          <Trans t={t} i18nKey="errors.contact" />
        </Typography>
      </Box>
    </Box>
  );
};

export default Unauthorized;
