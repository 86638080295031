import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    borderRadius: 4,
    border: '1px solid #6EB91D',
    backgroundColor: '#F0FBE5'
  },
  text: {
    flex: 1,
    margin: theme.spacing(0, 1),
    color: '#4A4A4A'
  },
  close: {
    cursor: 'pointer'
  }
}));

export default useStyles;
