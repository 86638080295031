import { Box, Typography } from '@material-ui/core';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigation } from '..';
import { Message } from '../../../../components';
import { DocumentStatus } from '../../../../domain/Document';
import useStores from '../../../../hooks/useStores';
import DocumentListItem from '../documentListItem/DocumentListItem';

const ConfirmExchange = () => {
  const { exchangeStore } = useStores();
  const { t } = useTranslation();
  const showWarning = exchangeStore.documents?.length === 0 || _.some(exchangeStore.documents, (d) => d.status !== DocumentStatus.Verified);

  return (
    <>
      <Box mt={3} mb={3}>
        <Typography variant="h2">
          <Trans t={t} i18nKey="confirm.title" />
        </Typography>
      </Box>
      {exchangeStore.documents?.map((document) => (
        <DocumentListItem key={document.id} document={document} />
      ))}
      {exchangeStore.documents != null &&
        exchangeStore.documents.length > 0 &&
        (showWarning ? (
          <Message logo="pending" title="confirm.warning.title" subtitle="confirm.warning.subtitle" />
        ) : (
          <Message logo="valid" title="confirm.valid.title" />
        ))}
      <Navigation
        shouldDisplayBack={true}
        shouldDisplayDraft={true}
        shouldDisplayNext={true}
        isNextDisabled={showWarning}
        nextTextKey="navigation.send"
      />
    </>
  );
};

export default observer(ConfirmExchange);
