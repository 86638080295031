import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Progress } from '../../components';
import useStores from '../../hooks/useStores';
import { Details, Result, SelectNotaries } from './components';

const VideoconferenceNew = () => {
  const { addVideoconferenceStore, notaryDirectoryStore } = useStores();

  useEffect(() => {
    addVideoconferenceStore.clear();
    notaryDirectoryStore.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {addVideoconferenceStore.index < 2 && <Progress options={addVideoconferenceStore.options} index={addVideoconferenceStore.index} />}
      {addVideoconferenceStore.index === 0 && <SelectNotaries />}
      {addVideoconferenceStore.index === 1 && <Details />}
      {addVideoconferenceStore.index === 2 && <Result />}
    </Box>
  );
};

export default observer(VideoconferenceNew);
