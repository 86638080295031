import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './Message.styles';

interface MessageProps {
  logo: string;
  title: string;
  subtitle?: string;
  overrideStyle?: {box: object, icon: object};
}

const Message = ({ logo, title, subtitle, overrideStyle }: MessageProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={styles.container} style={overrideStyle?.box}>
      <img style={overrideStyle?.icon} alt="" src={`${process.env.PUBLIC_URL}/images/messages/${logo}.svg`} />
      <Typography variant="h2" className={styles.title}>
        <Trans t={t} i18nKey={title} />
      </Typography>
      {subtitle && (
        <Typography variant="body2" className={styles.subtitle}>
          <Trans t={t} i18nKey={subtitle} />
        </Typography>
      )}
    </Box>
  );
};

export default Message;
