import { Button, Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Feedback, Loading, Message } from '../../../../components';
import useStores from '../../../../hooks/useStores';
import useStyles from './Result.styles';

const Result = () => {
  const { addVideoconferenceStore } = useStores();
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const back = () => addVideoconferenceStore.back();

  const end = () => {
    addVideoconferenceStore.end();
    history.push('/private/videoconferences');
  };

  useEffect(() => {
    addVideoconferenceStore.save();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!addVideoconferenceStore.loaded) {
    return <Loading />;
  }

  if (addVideoconferenceStore.error) {
    return (
      <Box className={styles.container}>
        <Message logo="camera-error" title="videoconferences.result.error.title" subtitle="videoconferences.result.error.subtitle" />
        <Button className={styles.action} variant="contained" color="secondary" onClick={back}>
          <Trans t={t} i18nKey="videoconferences.actions.back" />
        </Button>
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      <Message logo="camera-confirmed" title="videoconferences.result.success.title" subtitle="videoconferences.result.success.subtitle" />
      <Button className={styles.action} variant="contained" color="secondary" onClick={end}>
        <Trans t={t} i18nKey="videoconferences.actions.accept" />
      </Button>
      <Feedback id={addVideoconferenceStore.id} type="video" />
    </Box>
  );
};

export default observer(Result);
