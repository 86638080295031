import { makeAutoObservable, runInAction } from 'mobx';
import { IExchange } from '../domain/Exchange';
import { get, remove } from '../services/http.service';
import UserStore from './UserStore';

class ExchangeHistoryStore {
  loaded = false;
  error = false;

  updating = false;
  exchangeId?: number;

  drafts?: IExchange[];
  sent?: IExchange[];
  received?: IExchange[];

  userStore: UserStore;

  constructor(userStore: UserStore) {
    makeAutoObservable(this);

    this.userStore = userStore;
  }

  async loadSentExchanges() {
    try {
      this.loaded = false;
      const { data } = await get<IExchange[]>('/exchanges/sent');
      runInAction(() => {
        this.loaded = true;
        this.sent = data;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  async loadReceivedExchanges() {
    try {
      this.loaded = false;
      const { data } = await get<IExchange[]>('/exchanges/received');
      runInAction(() => {
        this.loaded = true;
        this.received = data;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  async loadDraftExchanges() {
    try {
      this.loaded = false;
      const { data } = await get<IExchange[]>('/exchanges/drafts');
      runInAction(() => {
        this.loaded = true;
        this.drafts = data;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  async removeDraft(exchange: IExchange) {
    try {
      this.updating = true;
      this.exchangeId = exchange.id;
      await remove<void>(`/exchanges/drafts/${exchange.id}`);
      runInAction(() => {
        this.updating = false;
        this.drafts?.splice(this.drafts?.indexOf(exchange), 1);
        this.userStore.badges();
      });
    } catch (e) {
      runInAction(() => {
        this.updating = false;
        this.error = true;
      });
    }
  }

  isUpdating(id: number) {
    return this.updating && this.exchangeId === id;
  }
}
export default ExchangeHistoryStore;
