import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  status: {
    borderRadius: 10,
    color: '#FFFFFF',
    padding: theme.spacing(0, 3),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px'
  },
  statusText: {
    fontSize: 11,
    fontWeight: 600
  }
}));

export default useStyles;
