import React, { Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import { IOption } from '../../../../domain/Menu';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './Breadcrumbs.styles';
import { compact, flatten } from 'lodash';

interface BreadcrumbProps {
  options: IOption[];
}

const Breadcrumbs = ({ options }: BreadcrumbProps) => {
  const { pathname, state } = useLocation<any>();
  const from = state?.from ?? pathname;
  const selectedOption = options.find((o) => from.toString().startsWith(o.route));
  const breadcrumbs = flatten(compact([state?.breadcrumb]));
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h1" className={styles.title}>
        <Trans t={t} i18nKey={selectedOption?.key} />
      </Typography>
      <Typography className={styles.option} variant="h6">
        <Trans t={t} i18nKey={selectedOption?.key} />
        {breadcrumbs.map((it: string, index: number) => (
          <Fragment key={`breadcrumb_${index}`}>
            <span className={styles.separator}>{' > '}</span>
            <span>{it}</span>
          </Fragment>
        ))}
      </Typography>
    </Box>
  );
};

export default Breadcrumbs;
