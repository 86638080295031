import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStores from '../../hooks/useStores';
import useStyles from './Progress.styles';

interface ProgressProps {
  options: { number: number; text: string }[];
  index: number;
}

const Progress = ({ options, index }: ProgressProps) => {
  const { t } = useTranslation();
  const { themeStore } = useStores();
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      {options.map((item, idx) => (
        <Box key={idx} className={styles.container}>
          {idx === index && (
            <Box className={styles.progress}>
              <Typography variant="h2">{item.number}</Typography>
              <Typography variant="body2" className={styles.option}>
                <Trans t={t} i18nKey={item.text} />
              </Typography>
            </Box>
          )}
          {idx !== index && (
            <Box className={styles.other} style={{ backgroundColor: idx < index ? '#6EB91D' : themeStore.theme?.progress?.other }}>
              <Typography variant="h2">{item.number}</Typography>
            </Box>
          )}
          {idx !== options.length - 1 && (
            <Box className={styles.separator} style={{ backgroundColor: idx < index ? '#6EB91D' : themeStore.theme?.progress?.other }} />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default observer(Progress);
