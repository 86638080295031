import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.separator}`
  },
  location: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  contact: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  name: {
    flex: 2,
    fontWeight: 600
  },
  locationInfo: {
    color: theme.notary?.name
  },
  phone: {
    flex: 1,
    color: theme.notary?.contact,
    marginRight: theme.spacing(3)
  },
  icon: {
    padding: theme.spacing(0, 1)
  },
  mailIcon: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0, 1)
  },
  add: {
    fontWeight: 600,
    textTransform: 'none',
    height: '32px',
    '&:disabled': {
      color: 'white',
      height: '32px'
    },
  }
}));

export default useStyles;
