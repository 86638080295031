import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  count: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
    fontWeight: 600,
    color: theme.videoconferences?.item.value,
    textTransform: 'capitalize',
    cursor: 'pointer'
  },
  recipients: {
    backgroundColor: '#333333',
    width: '320px',
    padding: theme.spacing(1)
  },
  recipient: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 0)
  },
  name: {
    color: theme.palette.common.white,
    fontWeight: 600,
    flex: 1,
    textAlign: 'right',
    marginRight: theme.spacing(1)
  },
  status: {
    margin: theme.spacing(0, 1)
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      right: 130,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent #333333 transparent`
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  }
}));

export default useStyles;
