import { Box, MenuItem, MenuList, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PopoverFilter, { PopoverFilterHandle } from '../popoverFilter/PopoverFilter';
import useStyles from './OptionsFilter.styles';

interface OptionsFilterProps {
  textKey: string;
  options: { key: string; value: string; }[]
  onSelect: (value: string) => void
}

const OptionsFilter = ({ textKey, options, onSelect }: OptionsFilterProps) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const popoverRef = useRef<PopoverFilterHandle>(null);

  const handleSelect = (event: React.MouseEvent<HTMLElement>, value: string) => {
    onSelect(value);
    popoverRef.current?.close();

    event.stopPropagation();
  }

  return (
    <PopoverFilter ref={popoverRef} textKey={textKey}>
      <MenuList className={styles.options}>
        {options.map((option, index) => (
          <Box key={index}>
            <MenuItem className={styles.option} onClick={(e) => handleSelect(e, option.value)}>
              <Typography variant="h6">
                <Trans t={t} i18nKey={option.key} />
              </Typography>
            </MenuItem>
            {(index !== options.length - 1) && <Box className={styles.separator} />}
          </Box>
        ))}
      </MenuList>
    </PopoverFilter>
  );
}

export default OptionsFilter;
