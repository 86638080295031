import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Progress } from '../../components';
import useStores from '../../hooks/useStores';
import { observer } from 'mobx-react';
import { Box, Button, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './Onboarding.styles';
import { SelectProfile, ConfirmData, ConfirmCode } from './components';

const Onboarding = () => {
  const { onboardingStore } = useStores();
  const history = useHistory();
  const styles = useStyles();
  const { t } = useTranslation();

  const handleLater = () => {
    history.push('/');
  }

  useEffect(() => {
    if (onboardingStore.error) {
      history.push('/error/unauthorized');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStore.error]);

  useEffect(() => {
    if (onboardingStore.verified) {
      history.push('/private/received');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStore.verified]);

  return (
    <Box className={styles.container}>
      <img alt="" className={styles.logo} />
      <Box className={styles.box}>
        <Typography className={styles.title} variant="h2">
          <Trans t={t} i18nKey="onboarding.title" />
        </Typography>
        <Typography className={styles.info} variant="body2">
          <Trans t={t} i18nKey="onboarding.subtitle" />
        </Typography>

        {onboardingStore.index < 3 && <Progress options={onboardingStore.options} index={onboardingStore.index} />}
        {onboardingStore.index === 0 && <SelectProfile />}
        {onboardingStore.index === 1 && <ConfirmData />}
        {onboardingStore.index === 2 && <ConfirmCode />}
      </Box>
      <Box className={styles.actions}>
        <Button className={styles.later} onClick={handleLater} variant="outlined" color="primary">
          <Trans t={t} i18nKey="onboarding.actions.later" />
        </Button>
      </Box>
    </Box>
  );
};

export default observer(Onboarding);
