import { makeAutoObservable } from 'mobx';
import { IToken } from '../domain/Token';
import { get } from '../services/http.service';
import UserStore from './UserStore';
class TokenStore {
  userStore: UserStore;

  constructor(userStore: UserStore) {
    makeAutoObservable(this);

    this.userStore = userStore;
  }

  async generate(loadUser = true) {
    localStorage.removeItem('token');

    const { data } = await get<IToken>('/token/generate');
    localStorage.setItem('token', data.token);

    loadUser && this.userStore.load();
  }
}

export default TokenStore;
