import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import useStores from '../../hooks/useStores';
import { Loading } from '../../components';

const Login = () => {
  const { userStore, tokenStore, onboardingStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    tokenStore.generate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userStore.loaded) {
      history.push('/private/received');
    } else if (userStore.next) {
      const isPendingConfirmation = userStore.next.includes('confirm');
      if (isPendingConfirmation) {
        onboardingStore.verify();
      }

      history.push(userStore.next);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.loaded, userStore.next]);

  return <Loading />;
};

export default observer(Login);
