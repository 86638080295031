import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './Alert.styles';

interface AlertProps {
  show: boolean;
  messageKey: string;
}

const Alert = ({show, messageKey}: AlertProps) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(true);

  if (!show || !showAlert) {
    return null;
  }

  return (
    <Box className={styles.container}>
      <img alt="" src={`${process.env.PUBLIC_URL}/icons/ic_verified.svg`} />
      <Typography variant="body2" className={styles.text}>
        <Trans t={t} i18nKey={messageKey} />
      </Typography>
      <img className={styles.close} src={`${process.env.PUBLIC_URL}/icons/ic_close.svg`} alt="" onClick={() => setShowAlert(false)} />
    </Box>
  )
}

export default observer(Alert);