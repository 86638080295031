import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2)
  },
  separator: {
    borderLeft: `1px solid ${theme.palette.text.primary}`,
    height: '24px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  language: {
    borderBottom: '0px !important'
  }
}));

export default useStyles;
