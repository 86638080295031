import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ExchangeStatus, IExchange } from '../../../../domain/Exchange';
import useStores from '../../../../hooks/useStores';
import useStyles from './Documents.styles';
import { IDocument } from '../../../../domain/Document';

interface DocumentsProps {
  exchange: IExchange;
}

const Documents = ({ exchange }: DocumentsProps) => {
  const styles = useStyles();
  const history = useHistory();
  const { exchangeDetailStore, userStore } = useStores();
  const { t } = useTranslation();

  const canView = () => {
    if (exchange.status === ExchangeStatus.Archived) {
      return false;
    }

    if (userStore.user?.id === exchange.from.notaryId) {
      return true;
    }

    var recipient = exchange.recipients.find((r) => r.notary.notaryId === userStore.user?.id);
    return recipient?.downloadedAt != null;
  };

  const onDownloadReceipt = () => {
    if (userStore.user?.id === exchange.from.notaryId) {
      exchangeDetailStore.downloadReceipt(exchange.id);
    } else {
      const location = { pathname: `/private/received/download/${exchange.id}`, state: { breadcrumb: exchange.subject.toUpperCase() } };
      history.push(location);
    }
  };

  const showDocument = (doc: IDocument) => {
    const pathname = `/private/document/${doc.id}`;
    const state = {
      from: history.location.pathname,
      breadcrumb: [exchange.subject.toUpperCase(), doc.filename]
    };
    history.push({ pathname, state });
  };

  return (
    <Box className={styles.container}>
      <Typography className={styles.title} variant="h3">
        <Trans t={t} i18nKey="exchanges.documents" />
      </Typography>
      {exchange.documents.map((doc) => (
        <Box key={doc.id} className={styles.item}>
          <img alt="" src={`${process.env.PUBLIC_URL}/icons/ic_pdf.svg`} />
          <Typography className={styles.filename} variant="h4">
            {doc.filename}
          </Typography>
          {canView() && (
            <Button onClick={() => showDocument(doc)} className={styles.view} variant="contained" color="secondary">
              <Trans t={t} i18nKey="exchanges.actions.view" />
            </Button>
          )}
        </Box>
      ))}
      {exchange.status === ExchangeStatus.Completed && (
        <Box mt={8}>
          <Typography className={styles.title} variant="h3">
            <Trans t={t} i18nKey="exchanges.receipt" />
          </Typography>
          <Box className={styles.item}>
            <img alt="" src={`${process.env.PUBLIC_URL}/icons/ic_pdf.svg`} />
            <Typography className={styles.filename} variant="h4">
              {exchange.subject}
            </Typography>
            <Button onClick={onDownloadReceipt} className={styles.view} variant="contained" color="primary">
              {!exchangeDetailStore.receipt && <Trans t={t} i18nKey="exchanges.actions.receipt" />}
              {exchangeDetailStore.receipt && <CircularProgress size={16} className={styles.progress} />}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default observer(Documents);
