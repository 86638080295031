import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './RecipientListItem.styles';
import { useTranslation } from 'react-i18next';
import { INotary } from '../../domain/Notary';

interface RecipientListItemProps {
  notary: INotary;
  onRemove: (notary: INotary) => void;
}

const RecipientListItem = ({ notary, onRemove }: RecipientListItemProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <Box className={styles.item}>
        <Typography variant="h4">{notary.name}</Typography>
      </Box>
      <Box className={styles.item}>
        <img className={styles.icon} src={`${process.env.PUBLIC_URL}/images/exchanges/pin.svg`} alt="" />
        <Typography className={styles.name} variant="body2">
          {notary.address}, {notary.city}, {t(`countries.${notary.country}`)}
        </Typography>
      </Box>
      <Box className={styles.actions} onClick={() => onRemove(notary)}>
        <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/ic_close.svg`} alt="" />
      </Box>
    </Box>
  );
};

export default RecipientListItem;
