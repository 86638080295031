import { Box, ClickAwayListener, Grow, Popper, RootRef, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import useStyles from './RecipientList.styles';
import { IVideoconference, IVideoconferenceRecipient } from '../../../../domain/Videoconference';

interface RecipientListProps {
  videconference: IVideoconference;
}

const RecipientList = ({ videconference }: RecipientListProps) => {
  const styles = useStyles();
  const anchorRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLSpanElement>(null);
  const [open, setOpen] = useState(false);

  const handleClickAway = () => {
    if (open) {
      setOpen(false);
    }
  };

  const icon = (recipient: IVideoconferenceRecipient) => {
    if (recipient.rejectedAt) {
      return 'ic_rejected';
    }

    if (recipient.acceptedAt) {
      return 'ic_accepted';
    }

    return 'ic_pending';
  };

  return (
    <Box>
      <RootRef rootRef={anchorRef}>
        <Typography variant="h4" className={styles.count} onClick={() => setOpen(!open)}>
          {videconference.count}
        </Typography>
      </RootRef>
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            className={styles.popper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom"
            modifiers={{ arrow: { enabled: true, element: arrowRef.current } }}
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }} timeout={600}>
                <Box>
                  <span className={styles.arrow} ref={arrowRef} />
                  <Box className={styles.recipients}>
                    {videconference.recipients.map((recipient) => (
                      <Box className={styles.recipient}>
                        <Typography variant="h4" className={styles.name}>
                          {recipient.notary.name}
                        </Typography>
                        <img
                          className={styles.status}
                          alt=""
                          src={`${process.env.PUBLIC_URL}/icons/videoconference/${icon(recipient)}.svg`}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default observer(RecipientList);
