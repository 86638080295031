import { makeAutoObservable, runInAction } from 'mobx';
import { IBadge, ISaveUser, IUser } from '../domain/User';
import { get, put, post } from '../services/http.service';

class UserStore {
  loaded = false;
  error = false;

  next?: string;
  saving = false;
  user?: IUser;
  userBadges?: IBadge;

  constructor() {
    makeAutoObservable(this);
  }

  get name() {
    return `${this.user?.firstname} ${this.user?.lastname}`;
  }

  get initials() {
    return `${this.user?.firstname.charAt(0)}${this.user?.lastname.charAt(0)}`.toUpperCase();
  }

  clear() {
    runInAction(() => {
      this.next = undefined;
      this.user = undefined;
      this.loaded = false;
    });
  }

  async load() {
    try {
      this.next = undefined;
      this.error = false;
      this.loaded = false;

      const { data } = await get<IUser>('/users/current');
      runInAction(() => {
        this.loaded = true;
        this.user = data;
      });
    } catch (e: any) {
      if (e.response?.status === 404) {
        runInAction(() => {
          this.next = '/onboarding';
          this.user = e.response?.data;
        });
      } else if (e.response?.status === 406) {
        runInAction(() => {
          this.next = '/onboarding/confirm';
          this.user = e.response?.data;
        });
      } else {
        runInAction(() => {
          this.next = '/error/unauthorized';
          this.error = true;
        });
      }
    }
  }

  async badges() {
    try {
      const { data } = await get<IBadge>('/users/badges');
      runInAction(() => {
        this.userBadges = data;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  async update(saveUser: ISaveUser, callback: () => void) {
    try {
      this.saving = true;

      const { data } = await put<ISaveUser, IUser>('/users/save', saveUser);
      runInAction(() => {
        this.saving = false;
        this.user = data;
        callback();
      });
    } catch (e) {
      runInAction(() => {
        this.saving = false;
        this.error = true;
      });
    }
  }

  async logout() {
    try {
      await post<any, any>('/users/logout');
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }
}

export default UserStore;
