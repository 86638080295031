import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 2
  },
  title: {
    color: theme.exchange?.documents.title,
    marginBottom: theme.spacing(2)
  },
  item: {
    borderTop: `1px solid ${theme.separator}`,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filename: {
    color: theme.exchange?.documents.filename,
    fontWeight: 600,
    flex: 1,
    marginLeft: theme.spacing(2)
  },
  view: {
    color: '#FFFFFF',
    fontWeight: 600,
    textTransform: 'none',
    minWidth: '132px',
    height: '32px',
    padding: '4px'
  },
  progress: {
    color: '#FFFFFF'
  }
}));

export default useStyles;
