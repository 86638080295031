import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './StatusBadge.styles';
import { IVideoconference, VideoconferenceStatus } from '../../../../domain/Videoconference';

interface StatusBadgeProps {
  videoconference: IVideoconference;
}

const StatusBadge = ({ videoconference }: StatusBadgeProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const color = () => {
    switch (videoconference.status) {
      case VideoconferenceStatus.Scheduled:
        return '#F5A524';
      case VideoconferenceStatus.Pending:
        return '#E849A5';
      default:
        return '#999999';
    }
  };

  const status = () => {
    return `videoconferences.status.${videoconference.status.toString().toLowerCase()}`;
  };

  return (
    <Box className={styles.status} style={{ backgroundColor: color() }}>
      <Typography className={styles.statusText}>{t(status())}</Typography>
    </Box>
  );
};

export default StatusBadge;
