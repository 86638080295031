import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './ReadFilter.styles';

interface ReadFilterProps {
  onReadChange: (value?: any) => void;
}

const ReadFilter = ({ onReadChange }: ReadFilterProps) => {

  const { t } = useTranslation();
  const styles = useStyles();

  const onSelectNotificationRead = () => {
    const newFilter = { read: { value: 'true' } };
    onReadChange(newFilter);
  }

  return (
    <Box className={styles.item} onClick={onSelectNotificationRead}>
      <Typography variant="body2">
        <Trans t={t} i18nKey="filter.read" />
      </Typography>
    </Box>
  )
}

export default ReadFilter;