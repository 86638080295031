import { Box, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Filter, Loading, Message, PagedList } from '../../components';
import useStores from '../../hooks/useStores';
import useStyles from './Notifications.styles';
import moment from 'moment';
import { useHistory } from 'react-router';
import { INotification, NotificationType } from '../../domain/Notification';
import { INotary } from '../../domain/Notary';
import _ from 'lodash';

const Notifications = () => {
  const styles = useStyles();
  const { notificationStore, userStore } = useStores();
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState<INotification[]>();

  const url = (notification: INotification) => {
    const videoTypes = [
      NotificationType.VideoAccepted,
      NotificationType.VideoCancelled,
      NotificationType.VideoReceived,
      NotificationType.VideoReceived,
      NotificationType.VideoRejected,
      NotificationType.VideoSent
    ];

    if (videoTypes.includes(notification.type)) {
      return { pathname: '/private/videoconferences/list' };
    }

    let pathname = `/private/received/details/${notification.exchange?.id}`;
    if (userStore.user?.id === notification.exchange?.from.notaryId) {
      pathname = `/private/sent/details/${notification.exchange?.id}`;
    }

    return { pathname: pathname, state: { breadcrumb: notification.exchange?.subject?.toUpperCase() } };
  };

  useEffect(() => {
    userStore.badges();
    notificationStore.list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notificationStore.loaded && notificationStore.notifications) {
      setData(notificationStore.notifications);
    }
  }, [notificationStore.loaded, notificationStore.notifications]);

  const onFilterChange = (filter: any) => {
    if (!filter) {
      setData(notificationStore.notifications);
      return;
    }

    let result = notificationStore.notifications!;
    if (filter.notary?.value) {
      const filterValue = filter.notary.value.toLowerCase();
      const predicate = (x: INotification) =>
        (x.type === NotificationType.Received && x.exchange?.from.name.toLowerCase().includes(filterValue)) ||
        (x.type === NotificationType.Sent && x.exchange?.recipients.find((n) => n.notary.name.toLowerCase().includes(filterValue)));

      result = _.filter(result, predicate) as INotification[];
    }

    if (filter.read && filter.read.value === 'true') {
      result = _.filter(result, (x) => x.readAt) as INotification[];
    }

    if (filter.type) {
      result = _.filter(result, (x) => x.type === filter.type.value);
    }

    if (filter.period) {
      const from = moment(filter.period.value.from).subtract(1, 'days');
      const to = moment(filter.period.value.to).add(1, 'days');
      result = _.filter(result, (x) => moment(x.createdAt).isBetween(from, to, 'day'));
    }

    setData(result);
  };

  const render = (notification: INotification) => {
    return (
      <Box key={notification.id} className={styles.item}>
        <Box className={styles.status}>{!notification.readAt && <Box className={styles.unread} />}</Box>
        <Box className={styles.content}>
          <Typography className={styles.contentTitle} variant="h3">{t(`notifications.types.${notification.type.toLowerCase()}`)}</Typography>
          <Typography variant="body2">{moment.utc(notification.createdAt).local().format('DD/MM/yyyy HH:mm')}</Typography>
        </Box>
        <Box className={styles.notaries}>
          {notification.type === NotificationType.Sent &&
            notification.exchange &&
            notification.exchange.recipients.map((recipient) => <Notary key={recipient.notary.notaryId} notary={recipient.notary} />)}
          {notification.type !== NotificationType.Sent && <Notary notary={notification.from} />}
          <Box className={styles.actions}>
            <Button onClick={() => history.push(url(notification))} className={styles.open} variant="contained" color="primary">
              <Trans t={t} i18nKey="notifications.actions.open" />
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  if (!notificationStore.loaded || !data) {
    return <Loading />;
  }

  if (notificationStore.notifications?.length === 0) {
    return <Message logo="notifications" title="notifications.empty" subtitle="notifications.hint" />;
  }

  return (
    <Box>
      <Filter filterType="notification" onFilterChange={onFilterChange} />
      <PagedList data={data} render={render} />
    </Box>
  );
};

interface NotaryProps {
  notary: INotary;
}

const Notary = ({ notary }: NotaryProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  if (!notary) {
    return null;
  }

  return (
    <Box className={styles.notary}>
      <Typography variant="h4" className={styles.notaryText}>
        {notary.name.toLowerCase()}
      </Typography>
      <Typography variant="body2" className={styles.notaryLocation}>{`${notary.city}, ${t(
        'countries.' + notary.country.toLowerCase()
      )}`}</Typography>
    </Box>
  );
};

export default observer(Notifications);
