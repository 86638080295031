import React from 'react';
import useStyles from './Navigation.styles';
import { Box, Button } from '@material-ui/core';
import useStores from '../../../../hooks/useStores';
import { Trans, useTranslation } from 'react-i18next';

interface NavigationProps {
  shouldDisplayBack?: boolean;
  shouldDisplayNext?: boolean;
  shouldDisplayDraft?: boolean;
  isNextDisabled?: boolean;
  nextTextKey?: string;
  onNext?: () => void;
}

const Navigation = ({ shouldDisplayBack, shouldDisplayNext, shouldDisplayDraft, isNextDisabled, nextTextKey, onNext }: NavigationProps) => {
  let icon: string; 
  const styles = useStyles();
  const { t } = useTranslation();
  const { exchangeStore } = useStores();

  const handleNext = () => {
    onNext && onNext();
    exchangeStore.next();
  };

  const handleBack = () => {
    exchangeStore.back();
  };

  const handleSaveDraft = () => {
    onNext && onNext();

    //onNext calls handleSubmit
    setTimeout(() => exchangeStore.saveDraft(), 500);
  };
  
  icon = nextTextKey === 'navigation.send' ? 'ic_send' : 'ic_arrow_right';

  return (
    <Box mt={2} mb={5} className={styles.actions}>
      {shouldDisplayBack && (
        <Button onClick={handleBack} className={styles.back} variant="contained">
          <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/ic_arrow_left.svg`} alt="" /> 
          <Trans t={t} i18nKey="navigation.back" />
        </Button>
      )}
      {shouldDisplayDraft && (
        <Button onClick={handleSaveDraft} className={styles.draft} variant="contained" color="secondary">
          <Trans t={t} i18nKey="navigation.draft" />
        </Button>
      )}
      {shouldDisplayNext && (
        <Button onClick={handleNext} disabled={isNextDisabled} className={styles.next} variant="contained" color="primary">
          <Trans t={t} i18nKey={nextTextKey ?? 'navigation.next'} />
          <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/${icon}.svg`} alt="" /> 
        </Button>
      )}
    </Box>
  );
};

export default Navigation;
