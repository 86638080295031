import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  box: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  logo: {
    display: 'block',
    margin: 'auto',
    width: '200px',
    marginTop: theme.spacing(8),
    content: `url(${process.env.PUBLIC_URL}/images/${theme.logos?.eudoc})`
  },
  contact: {
    marginTop: theme.spacing(3)
  },
  accept: {
    textTransform: 'none',
    color: '#FFFFFF',
    fontWeight: 600,
    margin: theme.spacing(3, 0)
  }
}));

export default useStyles;
