import { makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';

const logoWrapper: (theme: Theme) => CSSProperties = (theme: Theme) => ({
  position: 'absolute',
  bottom: 0,
  padding: theme.spacing(3)
});

const useStyles = makeStyles((theme) => ({
  content: {
    height: 'auto',
    padding: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      height: '50vh',
      padding: theme.spacing(2)
    }
  },
  ancert: {
    width: '120px',
    margin: 'auto',
    order: 10,
    [theme.breakpoints.up('md')]: {
      ...logoWrapper(theme),
      height: 'auto',
      left: 0,
      order: 1
    }
  },
  cnue: {
    width: '120px',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      ...logoWrapper(theme),
      height: 'auto',
      right: 0
    },
    [theme.breakpoints.up('lg')]: {
      width: '160px'
    }
  },
  sectionImage: {
    width: '140px',
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(3)
  },
  sectionTitle: {
    color: '#01A4DA',
    margin: theme.spacing(2, 0)
  },
  sectionText: {
    color: theme.palette.text.primary
  },
  logo: {
    display: 'block',
    margin: 'auto',
    width: '100%',
    height: 'auto'
  },
  upperWrapper: {
    width: '100%',
    height: '50vh',
    background: theme.background?.gradient
  },
  upperContent: {
    backgroundSize: 'cover',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/home/banner.svg)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '50vh'
  },
  upperTitle: {
    color: 'white',
    margin: theme.spacing(5, 13),
    textAlign: 'center',
    maxWidth: '1200px',
    fontSize: '48px'
  },
  upperNote: {
    fontSize: '12px',
    color: 'white'
  },
  eudoc: {
    marginTop: theme.spacing(5),
    width: '200px'
  },
  language: {
    position: 'absolute',
    top: 0,
    right: 10
  }
}));

export default useStyles;
