import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.background.paper}`
  },
  icon: {},
  data: {
    flex: 1,
    marginLeft: theme.spacing(3)
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  status: {
    borderRadius: 10,
    color: '#FFFFFF',
    padding: theme.spacing(0, 3),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px'
  },
  statusText: {
    fontSize: 11,
    fontWeight: 600
  },
  view: {
    color: '#FFFFFF',
    textTransform: 'none',
    fontWeight: 600,
    width: '150px'
  },
  delete: {
    marginRight: theme.spacing(2),
    textTransform: 'none',
    backgroundColor: '#999999',
    color: '#FFFFFF',
    fontWeight: 600
  },
  progress: {
    color: '#FFFFFF'
  }
}));

export default useStyles;
