import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const calendarTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#E849A5'
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    h4: {
      fontSize: 18
    },
    subtitle1: {
      fontSize: 14
    }
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0D4073',
        height: '70px'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: 0,
        padding: 0,
        color: 'white',
        backgroundColor: '#0D4073'
      },
      iconButton: {
        color: 'white',
        backgroundColor: '#0D4073'
      }
    },
    MuiPickersDay: {
      day: {
        borderRadius: 0
      },
      daySelected: {
        backgroundColor: '#E849A5',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: '#E849A5'
        }
      },
      current: {
        color: '#0D4073',
        fontWeight: 600
      }
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        backgroundColor: '#FFFFFF',
        fontSize: 16,
        width: '240px',
        color: '#090909',
        '& $notchedOutline': {
          borderColor: '#CCCCCC'
        },
        '&:hover': {
          borderColor: '#0D4073'
        }
      }
    }
  }
});

export default responsiveFontSizes(calendarTheme);
