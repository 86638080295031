import { makeAutoObservable, runInAction } from 'mobx';
import { INotification } from '../domain/Notification';
import { get } from '../services/http.service';

class NotificationStore {
  loaded = false;
  error = false;
  notifications?: INotification[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async list() {
    try {
      this.loaded = false;
      const { data } = await get<INotification[]>('/notifications/list');
      runInAction(() => {
        this.loaded = true;
        this.notifications = data;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }
}

export default NotificationStore;
