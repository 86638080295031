import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#79A6D2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '4px'
  },
  option: {
    color: '#FFFFFF',
    fontWeight: 600,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1)
  },
  icon: {
    cursor: 'pointer'
  }
}));

export default useStyles;
