import { Box, Popover, Typography } from '@material-ui/core';
import React, { ReactNode, useState, useImperativeHandle } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './PopoverFilter.styles';

export interface PopoverFilterHandle {
  close: () => void;
}
interface PopoverFilterProps {
  textKey: string;
  children: ReactNode;
}

const PopoverFilter = React.forwardRef<PopoverFilterHandle, PopoverFilterProps>((props, ref) => {
  const { textKey, children } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const styles = useStyles({ open });

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const onSelected = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(ref, () => ({
    close: onSelected
  }));

  return (
    <Box className={styles.item} onClick={(evt) => handleOpen(evt)}>
      <Typography variant="body2">
        <Trans t={t} i18nKey={textKey} />
      </Typography>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {children}
      </Popover>
    </Box>
  );
});

export default PopoverFilter;
