import React from 'react';
import { IExchange } from '../../../../domain/Exchange';
import { ExchangeListItem } from '../../../../components';
import useStyles from './DraftListItem.styles';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import useStores from '../../../../hooks/useStores';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';

interface DraftListItemProps {
  exchange: IExchange;
}

const DraftListItem = ({ exchange }: DraftListItemProps) => {
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { exchangeHistoryStore } = useStores();

  const removeDraft = () => {
    exchangeHistoryStore.removeDraft(exchange);
  };

  return (
    <ExchangeListItem exchange={exchange} mode="draft">
      <Box className={styles.actions}>
        <Button onClick={removeDraft} className={styles.delete} variant="contained">
          {exchangeHistoryStore.isUpdating(exchange.id) && <CircularProgress size={16} className={styles.progress} />}
          {!exchangeHistoryStore.isUpdating(exchange.id) && <Trans t={t} i18nKey="drafts.actions.delete" />}
        </Button>
        <Button
          onClick={() => history.push({ pathname: '/private/exchange-new', state: { id: exchange.id } })}
          className={styles.open}
          variant="contained"
          color="primary"
        >
          <Trans t={t} i18nKey="drafts.actions.open" />
        </Button>
      </Box>
    </ExchangeListItem>
  );
};

export default observer(DraftListItem);
