import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid #FFFFFF',
    color: '#FFFFFF',
    cursor: 'pointer'
  }
}));

export default useStyles;
