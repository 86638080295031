import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  box: {
    width: '50%'
  },
  title: {
    textAlign: 'center',
    marginTop: theme.spacing(5)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3)
  },
  column: {
    padding: theme.spacing(1)
  },
  label: {
    marginBottom: theme.spacing(1)
  },
  text: {
    width: '240px',
    backgroundColor: '#F5F5F5',
    height: '32px'
  },
  input: {
    width: '240px',
    height: '32px'
  },
  progress: {
    color: '#FFFFFF'
  },
  link: {
    color: '#01A4DA'
  },
  initials: {
    margin: 'auto',
    width: '96px',
    height: '96px',
    borderRadius: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(5)
  },
  initialsText: {
    fontWeight: 600,
    color: '#FFFFFF'
  },
  confirm: {
    textTransform: 'none',
    minWidth: '120px',
    fontWeight: 600,
    marginLeft: theme.spacing(1)
  },
}));

export default useStyles;
