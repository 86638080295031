import { Box, Switch, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStores from '../../../../hooks/useStores';
import useStyles from './ThemeSelector.styles';

const ThemeSelector = () => {
  const { t } = useTranslation();
  const { themeStore } = useStores();
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <Typography variant="body2">
        <Trans t={t} i18nKey={`themes.${themeStore.name}`} />
      </Typography>
      <Switch
        checked={themeStore.name === 'light'}
        onChange={() => {
          themeStore.changeTheme();
        }}
      />
    </Box>
  );
};

export default observer(ThemeSelector);
