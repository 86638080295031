import React, { ReactNode, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import useStores from '../../hooks/useStores';
import Loading from '../loading/Loading';
import { observer } from 'mobx-react';

interface ThemedLayoutProps {
  children: ReactNode;
}

const ThemedLayout = ({ children }: ThemedLayoutProps) => {
  const { themeStore } = useStores();

  useEffect(() => {
    themeStore.loadTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!themeStore.theme) {
    return <Loading />;
  }

  return <ThemeProvider theme={themeStore.theme}>{children}</ThemeProvider>;
};

export default observer(ThemedLayout);
