import React from 'react';
import OptionsFilter from '../optionsFilter/OptionsFilter';

interface TypeFilterProps {
  onTypeChange: (value?: any) => void;
}

const TypeFilter = ({ onTypeChange }: TypeFilterProps) => {

  const types = [
    { key: 'filter.notifications.sent', value: 'SENT' },
    { key: 'filter.notifications.received', value: 'RECEIVED' },
    { key: 'filter.notifications.downloaded', value: 'DOWNLOADED' }
  ];

  const onSelectNotificationType = (type: string) => {
    const notificationType = types.find(x => x.value === type)?.key;
    const newFilter = { type: { text: notificationType, value: type } };
    onTypeChange(newFilter);
  }

  return <OptionsFilter textKey="filter.type" options={types} onSelect={onSelectNotificationType} />
}

export default TypeFilter;