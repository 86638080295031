import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FEF5E7',
    border: '1px solid #F5A524',
    borderRadius: '4px',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2),
    display: 'flex',
    justifyContent: 'center'
  },
  text: {
    flex: 1,
    color: '#4A4A4A',
    padding: theme.spacing(2, 1)
  },
  close: {
    cursor: 'pointer'
  }
}));

export default useStyles;
