import { makeAutoObservable, runInAction } from 'mobx';
import { DocumentStatus, IDocument } from '../domain/Document';
import { IExchange, ISaveExchange } from '../domain/Exchange';
import { INotary } from '../domain/Notary';
import { get, post } from '../services/http.service';

class ExchangeStore {
  private readonly _maxSteps = 3;

  readonly options = [
    { number: 1, text: 'progress.select-notary' },
    { number: 2, text: 'progress.select-documents' },
    { number: 3, text: 'progress.confirm' }
  ];

  index = 0;

  id = 0;
  notaries?: INotary[] = [];
  type?: string;
  subject?: string;
  description?: string;
  documents?: IDocument[] = [];
  draft = false;

  error = false;
  loaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  async start(id?: number) {
    if (!id) {
      runInAction(() => {
        this.id = 0;
        this.subject = undefined;
        this.description = undefined;
        this.type = undefined;
        this.notaries = [];
        this.documents = [];

        this.reset();
      });

      return;
    }

    try {
      const { data } = await get<IExchange>(`/exchanges/drafts/${id}`);
      runInAction(() => {
        this.id = data.id;
        this.type = data.type;
        this.subject = data.subject;
        this.description = data.description;
        this.notaries = data.recipients.map((r) => r.notary);
        this.documents = data.documents;

        this.reset();
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  addNotary(notary: INotary) {
    runInAction(() => {
      this.notaries?.push(notary);
    });
  }

  removeNotary(notary: INotary) {
    runInAction(() => {
      this.notaries?.splice(this.notaries?.indexOf(notary), 1);
    });
  }

  addDocument(file: File, content?: string) {
    runInAction(() => {
      const encoded = content?.replace('data:application/pdf;base64,', '');
      let documentId = 0;
      if (this.documents && this.documents.length > 0) {
        var ids = this.documents.map((d) => d.id);
        documentId = Math.max(...ids) + 1;
      }

      const document = { id: documentId, size: file.size, filename: file.name, content: encoded, status: DocumentStatus.Pending };
      this.documents?.push(document);
    });
  }

  addSignature(document: IDocument, file: File, content?: string) {
    runInAction(() => {
      document.signature = content?.replace('data:application/octet-stream;base64,', '');
      document.verifiedAt = undefined;
      document.validation = undefined;
      document.status = DocumentStatus.Pending;
    });
  }

  removeDocument(document: IDocument) {
    runInAction(() => {
      this.documents?.splice(this.documents?.indexOf(document), 1);
    });
  }

  next() {
    runInAction(() => {
      this.index = Math.min(this.index + 1, this._maxSteps);
    });
  }

  back() {
    runInAction(() => {
      this.index = Math.max(this.index - 1, 0);
    });
  }

  review() {
    runInAction(() => {
      this.index = 2;
    });
  }

  saveData(subject: string, type: string, description?: string) {
    runInAction(() => {
      this.subject = subject;
      this.type = type;
      this.description = description;
    });
  }

  saveDraft() {
    runInAction(() => {
      this.draft = true;
      this.index = 3;
    });
  }

  end() {
    runInAction(() => {
      this.index = 0;
    });
  }

  async validateDocument(document: IDocument) {
    try {
      const { data } = await post<IDocument, IDocument>('/documents/validate', document);
      runInAction(() => {
        const stored = this.documents?.find((d) => d.id === document.id);
        if (stored) {
          stored.status = data.status;
          stored.verifiedAt = data.verifiedAt;
          stored.validation = data.validation;
        }
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  async save() {
    try {
      this.loaded = false;

      const saveExchange = {
        id: this.id,
        draft: this.draft,
        type: this.type!,
        subject: this.subject!,
        description: this.description,
        to: this.notaries!,
        documents: this.documents!
      };

      const { data } = await post<ISaveExchange, IExchange>('/exchanges/save', saveExchange, {});

      runInAction(() => {
        this.id = data.id;
        this.loaded = true;
        this.error = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loaded = true;
        this.error = true;
      });
    }
  }

  private reset() {
    this.index = 0;
    this.draft = false;
    this.error = false;
  }
}
export default ExchangeStore;
