import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import useStyles from './Option.styles';
import { useHistory, useLocation } from 'react-router';
import { IOption } from '../../../../domain/Menu';
import { Trans, useTranslation } from 'react-i18next';

interface OptionProps {
  option: IOption;
  menu: boolean;
}

const Option = ({ option, menu }: OptionProps) => {
  const styles = useStyles({ menu });
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isSelected = pathname.toString().startsWith(option.route);
  const optionStyle = isSelected ? styles.selected : styles.noSelected;
  const shouldDisplayBadge = (option.badge ?? 0) > 0;

  return (
    <Grid item xs={12}>
      <Box className={styles.item} style={{ cursor: 'pointer' }} onClick={() => history.push(option.route)}>
        {menu && isSelected && <Box className={styles.line} />}
        {menu && !isSelected && <Box className={styles.space} />}
        <Typography variant="h3" className={optionStyle}>
          <Trans t={t} i18nKey={option.key} />
        </Typography>
        {shouldDisplayBadge && (
          <Box className={styles.badges}>
            <Typography variant="h3" className={styles.text}>
              {option.badge}
            </Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default Option;
