import { makeStyles, Theme } from '@material-ui/core/styles';

type PagedStylesProps = {
  mode?: string;
};

const useStyles = makeStyles<Theme, PagedStylesProps>((theme) => ({
  container: {
    backgroundColor: ({ mode }) => `${mode === 'notaries' ? theme.palette.common.white : theme.paged?.backgroundColor}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  icon: {
    cursor: 'pointer',
    padding: theme.spacing(0, 1)
  },
  text: {
    padding: theme.spacing(0, 1),
    color: theme.paged?.color
  },
  records: {
    flex: 1,
    textAlign: 'right'
  }
}));

export default useStyles;
