import { IDocument } from './Document';
import { INotary } from './Notary';

export enum ExchangeStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Archived = 'ARCHIVED'
}

export interface IExchange {
  id: number;
  isDraft: boolean;
  type: string;
  subject: string;
  description?: string;
  status: ExchangeStatus;
  from: INotary;
  recipients: IRecipient[];
  updatedAt?: Date;
  sentAt?: Date;
  downloadedAt?: Date;
  archivedAt?: Date;
  expiresIn: number;
  documents: IDocument[];
}

export interface ISaveExchange {
  subject: string;
  description?: string;
  to: INotary[];
  documents: IDocument[];
}

export interface IRecipient {
  id: number;
  notary: INotary;
  downloadedAt?: Date;
  receivedAt?: Date;
}
