import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  initials: {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer'
  },
  text: {
    fontWeight: 600,
    color: '#FFFFFF'
  },
  username: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(1)
  }
}));

export default useStyles;
