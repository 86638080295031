import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import useStyles from './UserMenu.styles';
import LanguageSelector from '../../../languageSelector/LanguageSelector';
import UserOptions from '../userOptions/UserOptions';
import useStores from '../../../../hooks/useStores';
import ThemeSelector from '../themeSelector/ThemeSelector';

const UserMenu = () => {
  const styles = useStyles();
  const { userStore } = useStores();

  useEffect(() => {
    if (!userStore.user) {
      userStore.load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.user]);

  return (
    <Box className={styles.container}>
      <ThemeSelector />
      <Box className={styles.separator} />
      <LanguageSelector forScreen="menu" />
      <Box className={styles.separator} />
      <UserOptions />
    </Box>
  );
};

export default UserMenu;
