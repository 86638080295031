import UserStore from './UserStore';
import ThemeStore from './ThemeStore';
import ExchangeStore from './ExchangeStore';
import NotaryDirectoryStore from './NotaryDirectoryStore';
import ExchangeHistoryStore from './ExchangeHistoryStore';
import ExchangeDetailStore from './ExchangeDetailStore';
import DocumentDetailStore from './DocumentDetailStore';
import NotificationStore from './NotificationStore';
import OnboardingStore from './OnboardingStore';
import VideoconferenceStore from './VideoconferenceStore';
import AddVideoconferenceStore from './AddVideoconferenceStore';
import FeedbackStore from './FeedbackStore';
import TokenStore from './TokenStore';

export default class RootStore {
  userStore: UserStore;
  themeStore: ThemeStore;
  exchangeStore: ExchangeStore;
  notaryDirectoryStore: NotaryDirectoryStore;
  exchangeHistoryStore: ExchangeHistoryStore;
  exchangeDetailStore: ExchangeDetailStore;
  documentDetailStore: DocumentDetailStore;
  notificationStore: NotificationStore;
  onboardingStore: OnboardingStore;
  videoconferenceStore: VideoconferenceStore;
  addVideoconferenceStore: AddVideoconferenceStore;
  feedbackStore: FeedbackStore;
  tokenStore: TokenStore;

  constructor() {
    this.userStore = new UserStore();
    this.themeStore = new ThemeStore();
    this.exchangeStore = new ExchangeStore();
    this.notaryDirectoryStore = new NotaryDirectoryStore();
    this.exchangeHistoryStore = new ExchangeHistoryStore(this.userStore);
    this.exchangeDetailStore = new ExchangeDetailStore();
    this.documentDetailStore = new DocumentDetailStore();
    this.notificationStore = new NotificationStore();
    this.onboardingStore = new OnboardingStore(this.userStore, this.notaryDirectoryStore);
    this.videoconferenceStore = new VideoconferenceStore();
    this.addVideoconferenceStore = new AddVideoconferenceStore();
    this.feedbackStore = new FeedbackStore();
    this.tokenStore = new TokenStore(this.userStore);
  }
}
