import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import useStyles from './Public.styles';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { LanguageSelector } from '../../components';
import { isChrome, isEdgeChromium, isFirefox } from 'react-device-detect';
import useStores from '../../hooks/useStores';

const Public = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  const { themeStore, userStore } = useStores();

  const handleLogin = () => {
    userStore.clear();
    history.push('/login');
  };

  useEffect(() => {
    if (!isChrome && !isEdgeChromium && !isFirefox) {
      history.push('/error/not-supported');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isChrome && !isEdgeChromium && !isFirefox) {
    return null;
  }

  return (
    <Grid container>
      <Grid item sm={12} className={styles.upperWrapper}>
        <Box className={styles.language}>
          <LanguageSelector forScreen="public" />
        </Box>
        <Box className={styles.upperContent}>
          <img className={styles.eudoc} src={`${process.env.PUBLIC_URL}/images/eudoc-blue.svg`} alt="" />
          <Typography variant="h1" className={styles.upperTitle}>
            {t('public.banner.title')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            endIcon={<img src={`${process.env.PUBLIC_URL}/images/home/certificate.svg`} alt="" />}
            onClick={handleLogin}
          >
            {t('public.banner.action')}
          </Button>
          <p className={styles.upperNote}>{t('public.banner.note')}</p>
        </Box>
      </Grid>
      <Grid item sm={12} md={2}>
        <Box className={styles.content}>
          <Box className={styles.ancert}>
            <img className={styles.logo} src={`${process.env.PUBLIC_URL}/images/${themeStore.theme?.logos?.ancert}`} alt="" />
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={8}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Box className={styles.content}>
              <img className={styles.sectionImage} src={`${process.env.PUBLIC_URL}/images/home/home-01.svg`} alt="" />
              <Typography variant="h3" className={styles.sectionTitle}>
                <Trans t={t} i18nKey="public.sections.one.title" />
              </Typography>
              <Typography className={styles.sectionText} variant="body2">
                <Trans t={t} i18nKey="public.sections.one.text" />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={styles.content}>
              <img className={styles.sectionImage} src={`${process.env.PUBLIC_URL}/images/home/home-02.svg`} alt="" />
              <Typography variant="h3" className={styles.sectionTitle}>
                <Trans t={t} i18nKey="public.sections.two.title" />
              </Typography>
              <Typography className={styles.sectionText} variant="body2">
                <Trans t={t} i18nKey="public.sections.two.text" />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={styles.content}>
              <img className={styles.sectionImage} src={`${process.env.PUBLIC_URL}/images/home/home-03.svg`} alt="" />
              <Typography variant="h3" className={styles.sectionTitle}>
                <Trans t={t} i18nKey="public.sections.three.title" />
              </Typography>
              <Typography className={styles.sectionText} variant="body2">
                <Trans t={t} i18nKey="public.sections.three.text" />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} md={2}>
        <Box className={styles.content}>
          <Box className={styles.cnue}>
            <img className={styles.logo} src={`${process.env.PUBLIC_URL}/images/${themeStore.theme?.logos?.cnue}`} alt="" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default observer(Public);
