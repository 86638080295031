import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8)
  },
  title: {
    color: theme.palette.text.secondary,
    textAlign: 'center'
  },
  result: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    margin: theme.spacing(1, 3)
  },
  rates: {
    textAlign: 'center',
    margin: theme.spacing(3, 0)
  },
  rate: {
    margin: theme.spacing(0, 1),
    cursor: 'pointer'
  }
}));

export default useStyles;
