import { makeAutoObservable, runInAction } from 'mobx';
import { IFeedbackRequest } from '../domain/Feedback';
import { post } from '../services/http.service';

class FeedbackStore {
  result = false;

  constructor() {
    makeAutoObservable(this);
  }

  clear() {
    runInAction(() => {
      this.result = false;
    });
  }

  async save(type: 'video' | 'exchange' | 'recipient', id: number, rate: string) {
    try {
      await post<IFeedbackRequest, void>(`/feedback/save/${type}`, { id, feedback: rate });
      runInAction(() => {
        this.result = true;
      });
    } catch (e) {
      console.error(e);
    }
  }
}

export default FeedbackStore;
