import OptionsFilter from '../optionsFilter/OptionsFilter';

interface StatusFilterProps {
  onStatusChange: (value?: any) => void;
}

const StatusFilter = ({ onStatusChange }: StatusFilterProps) => {
  const statuses = [
    { key: 'filter.statuses.completed', value: 'COMPLETED' },
    { key: 'filter.statuses.pending', value: 'PENDING' },
    { key: 'filter.statuses.archived', value: 'ARCHIVED' }
  ];

  const onSelectExchangeStatus = (status: string) => {
    const exchangeStatus = statuses.find((x) => x.value === status)?.key;
    const newFilter = { status: { text: exchangeStatus, value: status } };
    onStatusChange(newFilter);
  };

  return <OptionsFilter textKey="filter.status" options={statuses} onSelect={onSelectExchangeStatus} />;
};

export default StatusFilter;
