import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  action: {
    color: '#FFFFFF',
    marginTop: theme.spacing(3),
    textTransform: 'none',
    fontWeight: 600
  }
}));

export default useStyles;
