import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#E849A5'
    },
    secondary: {
      main: '#0D4073'
    },
    text: {
      primary: '#0D4073',
      secondary: '#090909'
    },
    background: {
      default: '#FFFFFF',
      paper: '#C9D9E8'
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    body2: {
      fontSize: 14
    },
    h1: {
      fontSize: 48,
      fontFamily: 'Open Sans Light'
    },
    h2: {
      fontSize: 28
    },
    h3: {
      fontSize: 18
    },
    h4: {
      fontSize: 16
    },
    h5: {
      fontSize: 14
    },
    h6: {
      fontSize: 13
    },
    button: {
      fontSize: 13,
      fontWeight: 400
    }
  },
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 0
      }
    },
    MuiList: {
      root: {
        backgroundColor: '#FFFFFF'
      },
      padding: {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    },
    MuiOutlinedInput: {
      root: {
        minHeight: '32px',
        fontSize: 13,
        borderRadius: 0,
        '& $notchedOutline': {
          borderColor: '#CCCCCC'
        },
        '&:hover $notchedOutline': {
          borderColor: '#0D4073'
        }
      }
    },
    MuiTextField: {
      root: {
        backgroundColor: '#FFFFFF',
        fontSize: 13,
        width: '240px',
        color: '#090909',
        '& ::placeholder': {
          color: '#ABABAB'
        }
      }
    },
    MuiButton: {
      contained: {
        boxShadow: 'none'
      }
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0
        }
    }
  },
  background: {
    gradient: 'linear-gradient(rgba(121, 166, 210, 1), rgba(153, 33, 116, 1))'
  },
  menu: {
    color: '#F5F5F5'
  },
  progress: {
    item: '#0D4073',
    color: '#FFFFFF',
    other: '#C9D9E8'
  },
  notary: {
    name: '#354052',
    contact: '#4A4A4A',
    pin: 'ic_pin.svg',
    phone: 'ic_phone.svg'
  },
  language: {
    arrow: {
      public: 'ic_arrow_down_white.svg',
      menu: 'ic_arrow_down.svg'
    }
  },
  separator: '#C9D9E8',
  logos: {
    eudoc: 'eudoc-blue.svg',
    eudocPowered: 'eudoc-powered.svg',
    ancert: 'ancert.svg',
    cnue: 'cnue.svg'
  },
  exchange: {
    header: {
      color: '#0D4073',
      backgroundColor: '#E4ECF3'
    },
    description: {
      color: '#0D4073',
      backgroundColor: '#FAFAFA'
    },
    documents: {
      title: '#000000',
      filename: '#354052'
    },
    history: {
      title: '#000000',
      backgroundColor: '#E4ECF3',
      option: '#0D4073',
      action: '#0D4073',
      name: '#000000',
      time: '#54647A'
    }
  },
  paged: {
    backgroundColor: '#EEEEEE',
    color: '#263238',
    arrows: {
      leftLast: 'ic_left_last.svg',
      left: 'ic_left.svg',
      right: 'ic_right.svg',
      rightLast: 'ic_right_last.svg'
    }
  },
  videoconferences: {
    menu: '#EAEAEA',
    text: '#737373',
    item: {
      title: '#54647A',
      value: '#0D4073'
    }
  }
});

export default responsiveFontSizes(lightTheme);
