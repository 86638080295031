import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Link, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Loading } from '../../../../components';
import { ICreateUser } from '../../../../domain/User';
import useStores from '../../../../hooks/useStores';
import i18n from '../../../../locales/i18n';
import useStyles from './ConfirmData.styles';

const ConfirmData = () => {
  const styles = useStyles();
  const { userStore, onboardingStore } = useStores();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    watch
  } = useForm({ mode: 'onChange' });
  const useSameNotificationEmail = watch('useSameNotificationEmail');

  const languageKey = () => {
    let language = 'en';
    let languages = ['es', 'de'];
    languages.forEach((l) => {
      if (i18n.language?.startsWith(l)) {
        language = l;
      }
    });

    return language;
  };

  const onSubmit = (data: ICreateUser) => {
    onboardingStore.create(data);
  };

  const onBack = () => {
    onboardingStore.back();
  };

  useEffect(() => {
    if (onboardingStore.notary) {
      trigger();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStore.notary]);

  if (!onboardingStore.notary) {
    return <Loading />;
  }

  return (
    <Box>
      <Box className={styles.row}>
        <Box className={styles.column}>
          <Typography className={styles.label} variant="body2">
            <Trans t={t} i18nKey="onboarding.confirm.id" />
          </Typography>
          <TextField className={styles.text} variant="outlined" size="small" disabled={true} value={userStore.user?.identificationNumber} />
        </Box>
        <Box className={styles.column}>
          <Typography className={styles.label} variant="body2">
            <Trans t={t} i18nKey="onboarding.confirm.name" />
          </Typography>
          <TextField className={styles.text} variant="outlined" size="small" disabled={true} value={userStore.user?.firstname} />
        </Box>
        <Box className={styles.column}>
          <Typography className={styles.label} variant="body2">
            <Trans t={t} i18nKey="onboarding.confirm.surname" />
          </Typography>
          <TextField className={styles.text} variant="outlined" size="small" disabled={true} value={userStore.user?.lastname} />
        </Box>
      </Box>
      <Box className={styles.row}>
        <Box className={styles.column}>
          <Typography className={styles.label} variant="body2">
            <Trans t={t} i18nKey="onboarding.confirm.email" />
          </Typography>
          <TextField className={styles.text} variant="outlined" size="small" disabled={true} value={onboardingStore.notary?.mail} />
        </Box>
        <Box className={styles.infoColumn}>
          <Typography className={styles.label} variant="body2">
            &nbsp;
          </Typography>
          <Box className={styles.info}>
            <img alt="" src={`${process.env.PUBLIC_URL}/icons/ic_info.svg`} />
            <Typography className={styles.infoText} variant="body2">
              <Trans t={t} i18nKey="onboarding.confirm.info" />
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className={styles.notificationRow}>
        <FormControlLabel
          control={
            <Controller
              name="useSameNotificationEmail"
              defaultValue={true}
              control={control}
              render={({ field }) => <Checkbox defaultChecked={true} color="primary" {...field} />}
            />
          }
          label={
            <Typography variant="body2">
              <Trans t={t} i18nKey="onboarding.confirm.change" />
            </Typography>
          }
        />
      </Box>

      {!useSameNotificationEmail && (
        <Box className={styles.notificationEmailRow}>
          <Box className={styles.column}>
            <Typography className={styles.label} variant="body2">
              <Trans t={t} i18nKey="onboarding.confirm.notificationEmail" />
            </Typography>
            <Controller
              name="notificationEmail"
              control={control}
              defaultValue={onboardingStore.notary?.mail}
              rules={{ required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  className={styles.notificationEmailText}
                  autoComplete="email"
                  size="small"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                />
              )}
            />
          </Box>
          <Box className={styles.infoColumn} />
        </Box>
      )}

      <Box className={styles.legalRow}>
        <FormControlLabel
          control={
            <Controller
              name="acceptTerms"
              defaultValue={false}
              control={control}
              rules={{ required: true }}
              render={({ field }) => <Checkbox color="primary" {...field} />}
            />
          }
          label={
            <Typography variant="body2">
              <Trans t={t} i18nKey="legal.accept">
                I accept the{' '}
                <Link
                  className={styles.link}
                  href={`${process.env.PUBLIC_URL}/docs/terms-and-conditions.${languageKey()}.pdf`}
                  target="_blank"
                >
                  service conditions
                </Link>{' '}
                and the
                <Link className={styles.link} href={`${process.env.PUBLIC_URL}/docs/privacy-policy.${languageKey()}.pdf`} target="_blank">
                  privacy policy
                </Link>
              </Trans>
            </Typography>
          }
        />
      </Box>

      <Box className={styles.actions}>
        <Button onClick={onBack} className={styles.back} variant="outlined" color="secondary">
          <Trans t={t} i18nKey="onboarding.actions.back" />
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={Object.keys(errors).length !== 0}
          className={styles.send}
          variant="contained"
          color="primary"
        >
          {onboardingStore.saving && <CircularProgress size={16} className={styles.progress} />}
          {!onboardingStore.saving && <Trans t={t} i18nKey="onboarding.actions.send" />}
        </Button>
      </Box>
    </Box>
  );
};

export default observer(ConfirmData);
