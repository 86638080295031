import { makeAutoObservable, runInAction } from 'mobx';
import { IDocument } from '../domain/Document';
import { get } from '../services/http.service';

class DocumentDetailStore {
  error = false;
  loaded = false;
  document?: IDocument = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  clear() {
    runInAction(() => {
      this.document = undefined;
    });
  }

  async load(id: number) {
    try {
      this.loaded = false;
      const { data } = await get<IDocument>(`/documents/details/${id}`);
      runInAction(() => {
        this.loaded = true;
        this.document = data;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  viewDocument(document: IDocument) {
    runInAction(() => {
      this.document = document;
    });
  }

  reset() {
    runInAction(() => {
      this.document = undefined;
    });
  }
}
export default DocumentDetailStore;
