import { DocumentStatus, IDocument } from '../domain/Document';

export const color = (document: IDocument) => {
  switch (document.status) {
    case DocumentStatus.Verified:
      return '#6EB91D';
    case DocumentStatus.Error:
      return '#CC001B';
    case DocumentStatus.NotSigned:
      return '#CC001B';
    default:
      return '#F5A524';
  }
};

export const icon = (document: IDocument) => {
  switch (document.status) {
    case DocumentStatus.Verified:
      return 'ic_ok';
    case DocumentStatus.Error:
      return 'ic_ko';
    case DocumentStatus.NotSigned:
      return 'ic_ko';
    default:
      return 'ic_pending';
  }
};

export const text = (document: IDocument) => {
  switch (document.status) {
    case DocumentStatus.Verified:
      return 'status.validated';
    case DocumentStatus.Error:
      return 'status.issues';
    case DocumentStatus.NotSigned:
      return 'status.not-signed';
    default:
      return 'status.pending';
  }
};
