import { IExchange } from './Exchange';
import { INotary } from './Notary';

export enum NotificationType {
  Sent = 'SENT',
  Received = 'RECEIVED',
  Downloaded = 'DOWNLOADED',
  VideoSent = 'VIDEO_SENT',
  VideoReceived = 'VIDEO_RECEIVED',
  VideoRejected = 'VIDEO_REJECTED',
  VideoAccepted = 'VIDEO_ACCEPTED',
  VideoCancelled = 'VIDEO_CANCELLED'
}

export interface INotification {
  id: number;
  createdAt: Date;
  readAt?: Date;
  type: NotificationType;
  exchange?: IExchange;
  from: INotary;
}
