import React, { useImperativeHandle, useState } from 'react';
import { Box, Fade, Typography } from '@material-ui/core';
import useStyles from './Paged.styles';
import { Trans, useTranslation } from 'react-i18next';
import useStores from '../../hooks/useStores';
import { observer } from 'mobx-react';
export interface PagedHandle {
  search: () => void;
}
interface PagedProps {
  total: number;
  size: number;
  mode?: string;
  fetch: (page: number, callback: (data: any[]) => void) => void;
  render: (item: any) => React.ReactElement<any, any>;
}

const Paged = React.forwardRef<PagedHandle, PagedProps>((props, ref) => {
  const { total, size, mode, fetch, render } = props;
  const decimal = total / size;
  const pages = total % size === 0 ? parseInt(decimal.toString()) : parseInt((decimal + 1).toString());

  const { themeStore } = useStores();
  const styles = useStyles({ mode });
  const { t } = useTranslation();
  const [index, setIndex] = useState(-1);
  const [paging, setPaging] = useState(false);
  const [items, setItems] = useState<any[]>();

  const from = index * size + 1;
  const to = Math.min(index * size + size, total);

  const first = () => {
    setPage(0);
  };

  const last = () => {
    setPage(pages - 1);
  };

  const next = () => {
    setPage(Math.min(index + 1, pages - 1));
  };

  const prev = () => {
    setPage(Math.max(index - 1, 0));
  };

  const setPage = (page: number, force?: boolean) => {
    const isCurrentPage = page === index;
    if (isCurrentPage && !force) {
      return;
    }

    setPaging(true);
    fetch(page, (data) => {
      setTimeout(() => {
        setIndex(page);
        setPaging(false);
        setItems(data);
      }, 300);
    });
  };

  const handleSearch = () => {
    setPage(0, true);
  };

  useImperativeHandle(ref, () => ({
    search: handleSearch
  }));

  if (index < 0 || total === 0) {
    return null;
  }

  return (
    <Box>
      <Fade in={!paging}>
        <Box>{items?.map(render)}</Box>
      </Fade>
      <Box className={styles.container}>
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/icons/paged/${themeStore.theme?.paged?.arrows.leftLast}`}
          alt=""
          onClick={first}
        />
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/icons/paged/${themeStore.theme?.paged?.arrows.left}`}
          alt=""
          onClick={prev}
        />
        <Typography className={styles.text} variant="body2">
          <Trans t={t} i18nKey="paged.index" values={{ index: index + 1, max: pages }} />
        </Typography>
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/icons/paged/${themeStore.theme?.paged?.arrows.right}`}
          alt=""
          onClick={next}
        />
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/icons/paged/${themeStore.theme?.paged?.arrows.rightLast}`}
          alt=""
          onClick={last}
        />
        <Box className={styles.records}>
          <Typography className={styles.text} variant="body2">
            <Trans t={t} i18nKey="paged.records" values={{ from: from, to: to, of: total }} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});

export default observer(Paged);
