import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import useStores from '../../hooks/useStores';
import { CompletedExchangeListItem, Filter, Loading, Message, PagedList } from '../../components';
import { Box } from '@material-ui/core';
import { IExchange } from '../../domain/Exchange';
import _ from 'lodash';
import moment from 'moment';

const Sent = () => {
  const { exchangeHistoryStore, userStore } = useStores();
  const [data, setData] = useState<IExchange[]>();

  useEffect(() => {
    userStore.badges();
    exchangeHistoryStore.loadSentExchanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (exchangeHistoryStore.loaded && exchangeHistoryStore.sent) {
      setData(exchangeHistoryStore.sent);
    }
  }, [exchangeHistoryStore.loaded, exchangeHistoryStore.sent]);

  const onFilterChange = (filter: any) => {
    if (!filter) {
      setData(exchangeHistoryStore.sent);
      return;
    }

    let result = exchangeHistoryStore.sent!;
    if (filter.notary?.value) {
      const filterValue = filter.notary.value.toLowerCase();
      const predicate = (x: IExchange) => x.recipients.find((n) => n.notary.name?.toLowerCase().includes(filterValue));

      result = _.filter(result, predicate) as IExchange[];
    }

    if (filter.status) {
      result = _.filter(result, (x) => x.status === filter.status.value);
    }

    if (filter.period) {
      const from = moment(filter.period.value.from).subtract(1, 'days');
      const to = moment(filter.period.value.to).add(1, 'days');
      result = _.filter(result, (x) => moment(x.sentAt).isBetween(from, to, 'day'));
    }

    setData(result);
  };

  if (!exchangeHistoryStore.loaded) {
    return <Loading />;
  }

  if (exchangeHistoryStore.sent?.length === 0) {
    return <Message logo="folder" title="sent.empty" subtitle="sent.hint" />;
  }

  return (
    <Box>
      <Filter filterType="exchange" onFilterChange={onFilterChange} />
      <PagedList data={data} render={(item: IExchange) => <CompletedExchangeListItem key={item.id} exchange={item} mode="sent" />} />
    </Box>
  );
};

export default observer(Sent);
