import { AxiosError } from 'axios';
import { store } from '../stores/InitStore';
import { axiosInstance } from './http.service';

const URLGenerateToken = '/token/generate';

export const errorInterceptor = async (error: AxiosError) => {
  if (401 === error?.response?.status) {
    if (!error?.config?.headers['retry-request'] && !error?.config?.url?.endsWith(URLGenerateToken)) {
      await store.tokenStore.generate(false);
      error.config.headers['retry-request'] = true;
      const newResponse = await axiosInstance.request(error?.config);
      return Promise.resolve(newResponse);
    } else {
      window.location.href = `${process.env.PUBLIC_URL}/error/unauthorized`;
    }
  }

  return Promise.reject(error);
};
