import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  description: {
    backgroundColor: theme.exchange?.description.backgroundColor,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  descriptionTitle: {
    color: theme.exchange?.description.color,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2)
  },
  lower: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(3)
  },
  navigation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(5)
  },
  back: {
    textTransform: 'none',
    backgroundColor: '#999999',
    color: '#FFFFFF',
    fontWeight: 600,
    height: '40px',
    minWidth: '108px',
    padding: '8px'
  }
}));

export default useStyles;
