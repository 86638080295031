import React, { useState } from 'react';
import { Box, Button, Fade, Typography } from '@material-ui/core';
import useStyles from './DocumentViewer.styles';
import { DocumentStatus, IDocument } from '../../domain/Document';
import ValidationItem from '../validationItem/ValidationItem';
import Loading from '../loading/Loading';
import PdfViewer from '../pdfViewer/PdfViewer';
import { Trans, useTranslation } from 'react-i18next';
import { color, icon, text } from '../../services/documentService';
import { useParams } from 'react-router-dom';
import useStores from '../../hooks/useStores';
import Message from '../message/Message';
import Dropzone from 'react-dropzone';
import { observer } from 'mobx-react';

interface DocumentDetailsParams {
  id?: string;
}
interface DocumentDetailProps {
  document?: IDocument;
  onBack: () => void;
}

const DocumentViewer = ({ document, onBack }: DocumentDetailProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { id } = useParams<DocumentDetailsParams>();
  const { exchangeStore } = useStores();
  const [attaching, setAttaching] = useState(false);

  const handleDelete = () => {
    exchangeStore.removeDocument(document!);
    onBack();
  };

  const handleAttachSignature = (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    files.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        exchangeStore.addSignature(document!, file, fileReader.result?.toString());
        exchangeStore.validateDocument(document!);
        setAttaching(false);
      };
    });
  };

  if (!document) {
    return <Loading />;
  }

  return (
    <Box>
      <Typography variant="h2">Document</Typography>
      <Box className={styles.box}>
        <Box className={styles.item}>
          <img src={`${process.env.PUBLIC_URL}/icons/${icon(document)}.svg`} alt="" />
          <Box className={styles.data}>
            <Typography className={styles.filename} variant="h4">
              {document.filename}
            </Typography>
            {!id && (
              <Box className={styles.status} style={{ backgroundColor: color(document) }}>
                <Typography className={styles.statusText}>
                  <Trans t={t} i18nKey={text(document)} />
                </Typography>
              </Box>
            )}
          </Box>
          {document.status === DocumentStatus.NotSigned && (
            <Box className={styles.buttons}>
              <Button variant="contained" color="primary" className={styles.delete} onClick={handleDelete}>
                {t('confirm.actions.delete')}
              </Button>
              <Button variant="contained" color="primary" className={styles.action} onClick={() => setAttaching(!attaching)}>
                {t('confirm.actions.attach')}
              </Button>
            </Box>
          )}
        </Box>
        {document.status === DocumentStatus.NotSigned && (
          <Typography className={styles.container} variant="h4">
            <Trans t={t} i18nKey="validation.not-signed" />
          </Typography>
        )}
        {attaching && (
          <Fade in={attaching} timeout={2000}>
            <Box mt={3} className={styles.drop}>
              <Dropzone onDrop={handleAttachSignature} accept={['.pkcs7']} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()} className={styles.content}>
                    <input {...getInputProps()} />
                    <Message logo="document" title="documents.select" subtitle="documents.attach-hint" />
                    <Button className={styles.action} variant="contained" color="primary">
                      <Trans i18nKey="documents.actions.add" />
                    </Button>
                  </Box>
                )}
              </Dropzone>
            </Box>
          </Fade>
        )}
        {document.status !== DocumentStatus.NotSigned && (
          <Box className={styles.container}>
            <ValidationItem status={document.status} check={document.validation?.isContentValid} text="content" />
            <ValidationItem status={document.status} check={document.validation?.isExpiredValid} text="expired" />
            <ValidationItem status={document.status} check={document.validation?.isRevokedValid} text="revoked" />
            <ValidationItem status={document.status} check={document.validation?.isTrustedValid} text="trusted" />
            <ValidationItem status={document.status} check={document.validation?.isNotaryValid} text="notary" />
          </Box>
        )}
        <PdfViewer document={document} />
      </Box>
      <Box className={styles.actions}>
        <Button onClick={onBack} className={styles.back} variant="contained">
          <Trans t={t} i18nKey="documents.actions.back" />
        </Button>
      </Box>
    </Box>
  );
};

export default observer(DocumentViewer);
