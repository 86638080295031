import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#C52D96'
    },
    secondary: {
      main: '#01A4DA'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF'
    },
    background: {
      default: '#555555',
      paper: '#0D4073'
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    body2: {
      fontSize: 14
    },
    h1: {
      fontSize: 48,
      fontFamily: 'Open Sans Light'
    },
    h2: {
      fontSize: 28
    },
    h3: {
      fontSize: 18
    },
    h4: {
      fontSize: 16
    },
    h5: {
      fontSize: 14
    },
    h6: {
      fontSize: 13
    },
    button: {
      fontSize: 13,
      fontWeight: 400
    }
  },
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 0
      }
    },
    MuiList: {
      root: {
        backgroundColor: '#FFFFFF'
      },
      padding: {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    },
    MuiOutlinedInput: {
      root: {
        minHeight: '32px',
        color: '#090909',
        borderRadius: 0,
        '& $notchedOutline': {
          borderColor: '#CCCCCC'
        },
        '&:hover $notchedOutline': {
          borderColor: '#0D4073'
        },
        '&.Mui-disabled': {
          opacity: 0.3,
          color: 'black'
        }
      }
    },
    MuiTextField: {
      root: {
        backgroundColor: '#FFFFFF',
        fontSize: 13,
        width: '240px',
        color: '#090909',
        '& ::placeholder': {
          color: '#ABABAB'
        }
      }
    },
    MuiSelect: {
      root: {
        backgroundColor: '#FFFFFF',
        fontSize: 13,
        color: '#090909',
        '& ::placeholder': {
          color: '#ABABAB'
        }
      },
      selectMenu: {
        height: 'none',
        padding: '8px'
      },
      icon: {
        color: '#4A4A4A'
      }
    },
    MuiListItem: {
      root: {
        color: '#090909'
      }
    },
    MuiButton: {
      contained: {
        boxShadow: 'none'
      }
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0
      }
    }
  },
  background: {
    gradient: 'linear-gradient(rgba(13, 64, 115, 1), rgba(121, 166, 210, 1))'
  },
  menu: {
    color: '#333333'
  },
  progress: {
    item: '#FFFFFF',
    color: '#0D4073',
    other: '#79A6D2'
  },
  notary: {
    name: '#FFFFFF',
    contact: '#FFFFFF',
    pin: 'ic_pin_white.svg',
    phone: 'ic_phone.svg'
  },
  language: {
    arrow: {
      public: 'ic_arrow_down_white.svg',
      menu: 'ic_arrow_down_white.svg'
    }
  },
  separator: '#79A6D2',
  logos: {
    eudoc: 'eudoc.svg',
    eudocPowered: 'eudoc-powered-white.svg',
    ancert: 'ancert_white.svg',
    cnue: 'cnue_white.svg'
  },
  exchange: {
    header: {
      color: '#FFFFFF',
      backgroundColor: '#0D4073'
    },
    description: {
      color: '#79A6D2',
      backgroundColor: '#333333'
    },
    documents: {
      title: '#FFFFFF',
      filename: '#FFFFFF'
    },
    history: {
      title: '#FFFFFF',
      backgroundColor: '#333333',
      option: '#E849A5',
      action: '##FFFFFF',
      name: '#C9D9E8',
      time: '#79A6D2'
    }
  },
  paged: {
    backgroundColor: '#737373',
    color: '#FFFFFF',
    arrows: {
      leftLast: 'ic_left_last_dark.svg',
      left: 'ic_left_dark.svg',
      right: 'ic_right_dark.svg',
      rightLast: 'ic_right_last_dark.svg'
    }
  },
  videoconferences: {
    menu: '#292929',
    text: '#737373',
    item: {
      title: '#C9D9E8',
      value: '#FFFFFF'
    }
  }
});

export default responsiveFontSizes(darkTheme);
