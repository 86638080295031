import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  progress: {
    borderRadius: 30,
    padding: theme.spacing(2),
    color: theme.progress?.color,
    backgroundColor: theme.progress?.item,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center', 
    height: '55px'
  },
  option: {
    marginLeft: theme.spacing(1),
    fontWeight: 300
  },
  separator: {
    height: '4px',
    width: '100px'
  },
  other: {
    width: '48px',
    height: '48px',
    borderRadius: '24px',
    color: theme.progress?.color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default useStyles;
