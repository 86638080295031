import React, { useImperativeHandle, useState } from 'react';
import { Box, InputAdornment, OutlinedInput, Typography } from '@material-ui/core';
import useStyles from './NotaryFilter.styles';
import { Trans, useTranslation } from 'react-i18next';

export interface NotaryFilterHandle {
  reset: () => void;
}

interface NotaryFilterProps {
  onNotaryChange: (value?: any) => void;
}

const NotaryFilter = React.forwardRef<NotaryFilterHandle, NotaryFilterProps>((props, ref) => {

  const { onNotaryChange } = props;

  const { t } = useTranslation();
  const [notary, setNotary] = useState<string>();
  const styles = useStyles();

  const handleSearch = () => {
    onNotaryChange({ notary: { text: notary, value: notary } });
    setNotary('');
  }

  const onReset = () => {
    setNotary('');
  }

  useImperativeHandle(ref, () => ({
    reset: onReset
  }));

  return (
    <Box className={styles.container}>
      <Box className={styles.item}>
        <Typography variant="body2">
          <Trans t={t} i18nKey="filter.notary" />  
        </Typography>
          <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/ic_arrow_down_filled_white.svg`} alt="" />
      </Box>
      <Box>
        <OutlinedInput
          className={styles.input}
          autoComplete="disabled"
          margin="dense"
          value={notary}
          onChange={(evt) => setNotary(evt.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/ic_search.svg`} alt="" onClick={handleSearch} />
            </InputAdornment>
          }
        />
      </Box>
    </Box>
  )
});

export default NotaryFilter;
