import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#FAFAFA'
  },
  left: {
    flex: 1,
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  right: {
    flex: 1,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1)
  },
  calendar: {
    border: '1px solid #CCCCCC'
  },
  text: {
    color: '#090909'
  },
  actions: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FAFAFA'
  },
  apply: {
    textTransform: 'none',
    fontWeight: 600
  }
}));

export default useStyles;
