import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import spanishLocale from '../locales/es/translation.json';
import englishLocale from '../locales/en/translation.json';
import germanLocale from '../locales/de/translation.json';
import neerthelandsLocale from '../locales/nl/translation.json';

const resources = {
  en: {
    translation: englishLocale
  },
  es: {
    translation: spanishLocale
  },
  de: {
    translation: germanLocale
  },
  nl: {
    translation: neerthelandsLocale
  }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
