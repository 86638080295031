import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.background.paper}`
  },
  item: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center'
  },
  name: {
    color: theme.notary?.name
  },
  icon: {
    padding: theme.spacing(0, 1)
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer'
  }
}));

export default useStyles;
