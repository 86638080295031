import { Box, Button, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { Message } from '../../../../components';
import useStores from '../../../../hooks/useStores';
import Navigation from '../navigation/Navigation';
import useStyles from './SelectDocuments.styles';
import AddedDocumentListItem from '../addedDocumentListItem/AddedDocumentListItem';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';

const SelectDocuments = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { exchangeStore } = useStores();
  const {
    handleSubmit,
    trigger,
    control,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  const isNextDisabled = exchangeStore.documents?.length === 0 || Object.keys(errors).length !== 0;

  const onAddFile = (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    _.sortBy(files, (f) => f.name);

    files.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        exchangeStore.addDocument(file, fileReader.result?.toString());
      };
    });
  };

  const onNext = (data: { subject: string; type: string; description?: string }) => {
    exchangeStore.saveData(data.subject, data.type, data.description);
  };

  useEffect(() => {
    trigger();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box mt={3}>
        <Typography variant="h2">
          <Trans t={t} i18nKey="documents.title" />
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography className={styles.label} variant="body2">
          <Trans t={t} i18nKey="exchange.type.label" />
          <span style={{ color: '#F5A524' }}>*</span>
        </Typography>
        <Controller
          name="type"
          control={control}
          defaultValue={exchangeStore.type ?? ''}
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              className={styles.text}
              autoComplete="disabled"
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!error}
              displayEmpty={true}
              margin="dense"
            >
              <MenuItem key="" value="">
                <Trans t={t} i18nKey="exchange.type.placeholder" />
              </MenuItem>
              <MenuItem key="NOTARIAL_ACTS" value="NOTARIAL_ACTS">
                <Trans t={t} i18nKey="exchange.type.options.notarial_acts" />
              </MenuItem>
              <MenuItem key="POWERS_OF_ATTORNEY" value="POWERS_OF_ATTORNEY">
                <Trans t={t} i18nKey="exchange.type.options.powers_of_attorney" />
              </MenuItem>
              <MenuItem key="COMPANIES_AND_PARTNERSHIPS" value="COMPANIES_AND_PARTNERSHIPS">
                <Trans t={t} i18nKey="exchange.type.options.companies_and_partnerships" />
              </MenuItem>
              <MenuItem key="PERSONAL_AND_FAMILY_RELATIONS" value="PERSONAL_AND_FAMILY_RELATIONS">
                <Trans t={t} i18nKey="exchange.type.options.personal_and_family_relations" />
              </MenuItem>
              <MenuItem key="MORTGAGES_AND_LOANS" value="MORTGAGES_AND_LOANS">
                <Trans t={t} i18nKey="exchange.type.options.mortgages_and_loans" />
              </MenuItem>
              <MenuItem key="HOUSING_REAL_STATE" value="HOUSING_REAL_STATE">
                <Trans t={t} i18nKey="exchange.type.options.housing_real_state" />
              </MenuItem>
            </Select>
          )}
        />
      </Box>
      <Box mt={3}>
        <Typography className={styles.label} variant="body2">
          <Trans t={t} i18nKey="exchange.subject.label" />
          <span style={{ color: '#F5A524' }}>*</span>
        </Typography>
        <Controller
          name="subject"
          control={control}
          defaultValue={exchangeStore.subject ?? ''}
          rules={{ required: true, minLength: 20 }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              className={styles.text}
              placeholder={t('exchange.subject.placeholder')}
              autoComplete="disabled"
              size="small"
              variant="outlined"
              value={value}
              onChange={onChange}
              error={value !=="" && !!error}
              helperText={value !== "" && value.length < 20 ? t("helperText.min-length") : ' '}
            />
          )}
        />
      </Box>
      <Box mt={3}>
        <Typography className={styles.label} variant="body2">
          <Trans t={t} i18nKey="exchange.description.label" />
        </Typography>
        <Controller
          name="description"
          control={control}
          defaultValue={exchangeStore.description ?? ''}
          render={({ field: { onChange, value } }) => (
            <TextField
              rows={3}
              multiline={true}
              className={styles.textDescription}
              placeholder={t('exchange.description.placeholder')}
              autoComplete="disabled"
              size="small"
              variant="outlined"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Box>
      <Box mt={3} className={styles.container}>
        <Box mt={3} className={styles.filesContainer}>
        {exchangeStore.documents?.map((document) => (
          <AddedDocumentListItem key={document.filename} document={document} />
        ))}
        </Box>
        <Dropzone onDrop={onAddFile} accept={['.pdf']}>
          {({ getRootProps, getInputProps }) => (
            <Box {...getRootProps()} className={styles.drop}>
              <input {...getInputProps()} />
              <Message logo="document_small" title="documents.select" subtitle="documents.hint" overrideStyle={{box: {marginTop:'0px'}, icon: {border: "4px solid #0D4073"}}}/>
              <Button className={styles.action} variant="contained" color="primary">
                <Trans i18nKey="documents.actions.add" />
              </Button>
            </Box>
          )}
        </Dropzone>
      </Box>
      <Navigation
        shouldDisplayBack={true}
        shouldDisplayDraft={true}
        shouldDisplayNext={true}
        isNextDisabled={isNextDisabled}
        onNext={handleSubmit(onNext)}
      />
    </>
  );
};

export default observer(SelectDocuments);
