import React, { useEffect, useRef } from 'react';
import Paged, { PagedHandle } from '../paged/Paged';

interface PagedProps {
  data?: any[];
  render: (item: any) => React.ReactElement<any, any>;
}

const PagedList = ({ data, render }: PagedProps) => {
  const size = 6;
  const pagedRef = useRef<PagedHandle>(null);
  const handleFetch = (page: number, callback: (data: any[]) => void) => {
    if (!data) {
      return;
    }

    const start = page * size;
    const items = data.slice(start, start + size);

    callback(items);
  };

  useEffect(() => {
    if (data) {
      pagedRef.current?.search();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <Paged ref={pagedRef} total={data?.length ?? 0} size={size} render={render} fetch={handleFetch} />;
};

export default PagedList;
