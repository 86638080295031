import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  navigate: {
    fontWeight: 600,
    textTransform: 'none',
    minWidth: '148px',
    height: '40px',
    padding: '8px'
  }
}));

export default useStyles;
