import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.separator}`
  },
  content: {
    flex: 1
  },
  logo: {
    paddingRight: theme.spacing(2)
  },
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1)
  },
  title: {
    flex: 1
  },
  titleText: {
    textTransform: 'uppercase',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
  },
  notary: {
    marginLeft: theme.spacing(3)
  },
  notaryText: {
    fontWeight: 600,
    textAlign: 'right',
    textTransform: 'capitalize'
  },
  notaryLocation: {
    marginTop: theme.spacing(0.5),
    textAlign: 'right'
  },
  description: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(2)
  },
  actions: {
    marginLeft: theme.spacing(3)
  },
  arrowIcon: {
    cursor: 'pointer',
    transform: 'rotate(0deg)'
  },
  arrowIconUp: {
    cursor: 'pointer',
    transform: 'rotate(180deg)'
  }
}));

export default useStyles;
