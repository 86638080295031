import { Box, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FilteredOption, NotaryFilter, PeriodFilter, ReadFilter, TypeFilter } from './components';
import { NotaryFilterHandle } from './components/notaryFilter/NotaryFilter';
import StatusFilter from './components/statusFilter/StatusFilter';
import StatusVideoconferenceFilter from './components/statusFilter/StatusVideoconferenceFilter';
import useStyles from './Filter.styles';

interface FilterProps {
  onFilterChange: (filter: any) => void;
  filterType: 'notification' | 'exchange' | 'videos';
}

const Filter = ({ onFilterChange, filterType }: FilterProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const notaryRef = useRef<NotaryFilterHandle>(null);

  const [filter, setFilter] = useState<any>();

  const onChange = (value?: any) => {
    const newFilter = { ...filter, ...value };
    changeFilter(newFilter);
  };

  const onClear = () => {
    notaryRef.current?.reset();
    changeFilter(null);
  };

  const onRemove = (name: string) => {
    delete filter[name];
    changeFilter(filter);
  };

  const changeFilter = (newFilter: any) => {
    setFilter(newFilter);
    onFilterChange(newFilter);
  };

  return (
    <Box>
      <Box className={styles.container}>
        <NotaryFilter ref={notaryRef} onNotaryChange={onChange} />
        <PeriodFilter onPeriodChange={onChange} />
        {filterType === 'exchange' && <StatusFilter onStatusChange={onChange} />}
        {filterType === 'videos' && <StatusVideoconferenceFilter onStatusChange={onChange} />}
        {filterType === 'notification' && <TypeFilter onTypeChange={onChange} />}
        {filterType === 'notification' && <ReadFilter onReadChange={onChange} />}
        <Box className={styles.reset}>
          <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/ic_restart.svg`} alt="" onClick={onClear} />
          <Typography variant="body2" className={styles.resetText}>
            <Trans t={t} i18nKey="filter.reset" />
          </Typography>
        </Box>
      </Box>
      <Box style={{ display: 'flex' }}>
        {filter && Object.keys(filter).map((key) => <FilteredOption key={key} name={key} value={filter[key]} onRemove={onRemove} />)}
      </Box>
    </Box>
  );
};

export default Filter;
