import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Message } from '../../components';
import useStyles from './Error.styles';

const NotSupported = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <img alt="" className={styles.logo} />
      <Box className={styles.box}>
        <Message logo="unauthorized" title="errors.title" subtitle="errors.not-supported" />
        <Typography className={styles.contact} variant="body2">
          <Trans t={t} i18nKey="errors.contact" />
        </Typography>
      </Box>
    </Box>
  );
};

export default NotSupported;
