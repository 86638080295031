import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IVideoconference, VideoconferenceStatus } from '../../../../domain/Videoconference';
import useStores from '../../../../hooks/useStores';
import useStyles from './Actions.styles';

interface ActionsProps {
  videoconference: IVideoconference;
  onChange: (item: IVideoconference | null) => void;
}

const Actions = ({ videoconference, onChange }: ActionsProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { userStore, videoconferenceStore } = useStores();

  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [starting, setStarting] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  const onAccept = async () => {
    setAccepting(true);
    const result = await videoconferenceStore.accept(videoconference.id);
    setAccepting(false);

    onChange(result);
  };

  const onStart = async () => {
    setStarting(true);
    const url = await videoconferenceStore.start(videoconference.id);
    setStarting(false);

    window.open(url);
  };

  const onReject = async () => {
    setRejecting(true);
    const result = await videoconferenceStore.reject(videoconference.id);
    setRejecting(false);

    onChange(result);
  };

  const onCancel = async () => {
    setCancelling(true);
    const result = await videoconferenceStore.cancel(videoconference.id);
    setCancelling(false);

    onChange(result);
  };

  const shouldHideActions = [VideoconferenceStatus.Finished, VideoconferenceStatus.Rejected, VideoconferenceStatus.Cancelled].includes(
    videoconference.status
  );

  if (shouldHideActions) {
    return (
      <Box className={styles.actions}>
        <Typography className={styles.notAvailable}>
          <Trans t={t} i18nKey="videoconferences.notAvailable" />
        </Typography>
      </Box>
    );
  }

  if (userStore.user?.id === videoconference.from.notaryId) {
    return (
      <Box className={styles.actions}>
        <Button variant="contained" className={styles.secondary} onClick={onCancel}>
          {!cancelling && <Trans t={t} i18nKey="videoconferences.actions.cancel" />}
          {cancelling && <CircularProgress size={16} className={styles.progress} />}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onStart}
          className={styles.primary}
          disabled={!moment().isSame(moment.utc(videoconference.scheduledAt).local(), 'day')}
        >
          {!starting && <Trans t={t} i18nKey="videoconferences.actions.start" />}
          {starting && <CircularProgress size={16} className={styles.progress} />}
        </Button>
      </Box>
    );
  }

  const recipient = videoconference.recipients.find((r) => r.notary.notaryId === userStore.user?.id);
  return (
    <Box className={styles.actions}>
      <Button variant="contained" className={styles.secondary} onClick={onReject}>
        {!rejecting && <Trans t={t} i18nKey="videoconferences.actions.reject" />}
        {rejecting && <CircularProgress size={16} className={styles.progress} />}
      </Button>
      {recipient?.acceptedAt && (
        <Button
          variant="contained"
          color="primary"
          className={styles.primary}
          onClick={onStart}
          disabled={!moment().isSame(moment.utc(videoconference.scheduledAt).local(), 'day')}
        >
          {!starting && <Trans t={t} i18nKey="videoconferences.actions.start" />}
          {starting && <CircularProgress size={16} className={styles.progress} />}
        </Button>
      )}
      {!recipient?.acceptedAt && (
        <Button variant="contained" color="primary" className={styles.primary} onClick={onAccept}>
          {!accepting && <Trans t={t} i18nKey="videoconferences.actions.accept" />}
          {accepting && <CircularProgress size={16} className={styles.progress} />}
        </Button>
      )}
    </Box>
  );
};

export default Actions;
