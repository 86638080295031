import { makeAutoObservable, runInAction } from 'mobx';
import { INotary } from '../domain/Notary';
import { ICreateUser, IUser } from '../domain/User';
import { post, put } from '../services/http.service';
import NotaryDirectoryStore from './NotaryDirectoryStore';
import UserStore from './UserStore';

class OnboardingStore {
  private readonly _maxSteps = 3;
  readonly options = [
    { number: 1, text: 'onboarding.steps.select' },
    { number: 2, text: 'onboarding.steps.confirm' },
    { number: 3, text: 'onboarding.steps.verify' }
  ];

  index = 0;

  error = false;
  saving = false;
  resending = false;
  verifying = false;
  verified = false;

  notary?: INotary;

  userStore: UserStore;
  notaryDirectoryStore: NotaryDirectoryStore;

  constructor(userStore: UserStore, notaryDirectoryStore: NotaryDirectoryStore) {
    makeAutoObservable(this);

    this.userStore = userStore;
    this.notaryDirectoryStore = notaryDirectoryStore;
  }

  verify() {
    runInAction(() => {
      this.index = 2;
    });
  }

  async search(onConfigured: () => void) {
    try {
      runInAction(() => {
        this.notaryDirectoryStore.loading = true;
        this.notaryDirectoryStore.initialized = true;
      });

      await this.notaryDirectoryStore.getToken();
      const data = {
        country: this.userStore.user?.country === 'DE' ? 'deu' : 'esp',
        lastname: this.userStore.user?.lastname.toLowerCase()
      };

      this.notaryDirectoryStore.configure(data);
      onConfigured();
    } catch (e) {
      console.error(e);
    }
  }

  async select(directoryId: number) {
    try {
      runInAction(() => {
        this.index = 1;
      });

      const response = await this.notaryDirectoryStore.details(directoryId);
      runInAction(() => {
        this.notary = response;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  async create(saveUser: ICreateUser) {
    saveUser.notary = this.notary;

    try {
      this.saving = true;

      await post<ICreateUser, IUser>('/users/save', saveUser);
      runInAction(() => {
        this.saving = false;
        this.index = 2;
      });
    } catch (e) {
      runInAction(() => {
        this.saving = false;
        this.error = true;
      });
    }
  }

  async confirm(code: string) {
    try {
      this.verifying = true;

      const { data } = await put<void, IUser>(`/users/confirm/${code}`);
      runInAction(() => {
        this.verified = true;
        this.userStore.user = data;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.verifying = false;
      });
    }
  }

  back() {
    runInAction(() => {
      this.index = this.index - 1;
    });
  }

  async resend() {
    try {
      this.resending = true;
      await put<void, IUser>('/users/resend');
      runInAction(() => {
        this.resending = false;
      });
    } catch (e) {
      runInAction(() => {
        this.resending = false;
        this.error = true;
      });
    }
  }
}

export default OnboardingStore;
