import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.exchange?.header.backgroundColor,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row'
  },
  logo: {
    paddingRight: theme.spacing(2)
  },
  information: {
    flex: 1
  },
  notaries: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  subjectTitle: {
    textTransform: 'uppercase',
    color: theme.exchange?.header.color,
    fontWeight: 600
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1)
  },
  notary: {
    marginLeft: theme.spacing(3)
  },
  notaryText: {
    fontWeight: 600,
    textAlign: 'right',
    textTransform: 'capitalize'
  },
  notaryLocation: {
    marginTop: theme.spacing(0.5),
    textAlign: 'right'
  },
  actions: {
    marginLeft: theme.spacing(3)
  },
  download: {
    fontWeight: 600,
    textTransform: 'none',
    minWidth: '196px',
    height: '40px',
    padding: '8px'
  },
  progress: {
    color: '#FFFFFF'
  }
}));

export default useStyles;
