import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DocumentViewer } from '../../components';
import useStores from '../../hooks/useStores';

interface ParamTypes {
  id: string;
}

const DocumentDetail = () => {
  const { id } = useParams<ParamTypes>();
  const { documentDetailStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    documentDetailStore.load(+id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DocumentViewer document={documentDetailStore.document} onBack={() => history.goBack()} />;
};

export default observer(DocumentDetail);
