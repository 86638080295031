import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExchangeStatus, IExchange } from '../../domain/Exchange';
import useStyles from './ExchangeStatusBadge.styles';

interface ExchangeStatusBadgeProps {
  exchange: IExchange;
}

const ExchangeStatusBadge = ({ exchange }: ExchangeStatusBadgeProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const color = () => {
    if (exchange.isDraft) {
      return '#737373';
    }

    switch (exchange.status) {
      case ExchangeStatus.Archived:
        return '#737373';
      case ExchangeStatus.Completed:
        return '#6EB91D';
      default:
        return '#F5A524';
    }
  };

  const status = () => {
    if (exchange.isDraft) {
      return 'status.draft';
    }

    return `status.${exchange.status.toString().toLowerCase()}`;
  };

  return (
    <Box className={styles.status} style={{ backgroundColor: color() }}>
      <Typography className={styles.statusText}>{t(status())}</Typography>
    </Box>
  );
};

export default ExchangeStatusBadge;
