import { Box, ClickAwayListener, Grow, MenuItem, MenuList, Popper, RootRef } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode, Ref, useRef, useState, useImperativeHandle } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './PopperMenu.styles';
import useStores from '../../hooks/useStores';

interface PopperMenuProps {
  onSelected: (option: string) => void;
  options: { key: string; value: string }[];
  menuRef: Ref<ProperMenuHandle>;
  children: ReactNode;
}

export interface ProperMenuHandle {
  open: () => void;
}

const PopperMenu = ({ onSelected, options, menuRef, children }: PopperMenuProps) => {
  const styles = useStyles();
  const anchorRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLSpanElement>(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { userStore } = useStores();

  useImperativeHandle(menuRef, () => ({
    open: handleOpen
  }));

  const handleClickAway = () => {
    if (open) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSelect = (value: string, key: string) => {
    setOpen(false);
    onSelected(value);
    if (key === 'options.logout') {
      userStore.logout();
    }
  };

  return (
    <Box className={styles.container}>
      <RootRef rootRef={anchorRef}>{children}</RootRef>
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            className={styles.popper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
            modifiers={{ arrow: { enabled: true, element: arrowRef.current } }}
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }} timeout={600}>
                <Box>
                  <span className={styles.arrow} ref={arrowRef} />
                  <MenuList className={styles.options}>
                    {options.map((option) => (
                      <MenuItem key={option.key} className={styles.option} onClick={() => handleSelect(option.value, option.key)}>
                        <Trans t={t} i18nKey={option.key} />
                      </MenuItem>
                    ))}
                  </MenuList>
                </Box>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default observer(PopperMenu);
