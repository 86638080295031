import { Box, Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SearchNotaries } from '../../../../components';
import { PagedHandle } from '../../../../components/paged/Paged';
import { INotary } from '../../../../domain/Notary';
import useStores from '../../../../hooks/useStores';
import useStyles from './SelectProfile.styles';

const SelectProfile = () => {
  const styles = useStyles();
  const pagedRef = useRef<PagedHandle>(null);
  const { onboardingStore, notaryDirectoryStore } = useStores();
  const [showSearch, setShowSearch] = useState(false);
  const { t } = useTranslation();

  const handleNotFound = () => {
    notaryDirectoryStore.reset();
    setShowSearch(true);
  };

  const handleSelect = (notary: INotary) => {
    onboardingStore.select(notary.directoryId);
  };

  useEffect(() => {
    onboardingStore.search(() => {
      pagedRef.current?.search();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!notaryDirectoryStore.initialized) {
    return null;
  }

  return (
    <Box>
      <SearchNotaries ref={pagedRef} showTitle={false} hintKey="search.empty.hint" showSearch={showSearch} onSelect={handleSelect} />
      {!showSearch && (
        <Box className={styles.actions}>
          <Button className={styles.notFound} onClick={handleNotFound} variant="outlined" color="primary">
            <Trans t={t} i18nKey="onboarding.select.not-found" />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default observer(SelectProfile);
