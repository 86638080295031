import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2)
  },
  title: {
    color: theme.exchange?.history.title,
    marginBottom: theme.spacing(2)
  },
  history: {
    backgroundColor: theme.exchange?.history.backgroundColor,
    padding: theme.spacing(3),
    width: theme.spacing(50),
    float: 'right'
  },
  item: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    marginBottom: theme.spacing(3)
  },
  numbers: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  number: {
    border: `2px solid ${theme.exchange?.history.option}`,
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  separator: {
    marginTop: theme.spacing(1),
    border: '1px dashed #01CBF4'
  },
  data: {
    flex: 7
  },
  text: {
    color: theme.exchange?.history.option,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  action: {
    color: theme.exchange?.history.action,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  name: {
    color: theme.exchange?.history.name,
    fontWeight: 600,
    padding: theme.spacing(1, 0),
    textTransform: 'capitalize'
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },
  timestap: {
    color: theme.exchange?.history.time,
    marginLeft: theme.spacing(1)
  }
}));

export default useStyles;
