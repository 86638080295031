import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  options: {
    backgroundColor: '#FAFAFA',
    borderRadius: 4
  },
  option: {
    color: '#706F6F',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      fontWeight: 600,
      color: '#0D4073'
    }
  },
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: '#EDEDED'
  }
}));

export default useStyles;
