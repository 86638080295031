import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomeLayout from '../../components/layouts/HomeLayout';
import {
  Sent,
  Received,
  Draft,
  Notifications,
  SendNew,
  ExchangeDetail,
  DocumentDetail,
  Profile,
  Videoconferences,
  VideoconferenceNew,
  Downloaded
} from '../index';

const Home = () => {
  return (
    <HomeLayout>
      <Switch>
        <Route path="/private/sent" exact={true} component={Sent} />
        <Route path="/private/sent/details/:id" component={ExchangeDetail} />
        <Route path="/private/received" exact={true} component={Received} />
        <Route path="/private/received/details/:id" component={ExchangeDetail} />
        <Route path="/private/received/download/:id" component={Downloaded} />
        <Route path="/private/draft" component={Draft} />
        <Route path="/private/notifications" component={Notifications} />
        <Route path="/private/exchange-new" component={SendNew} />
        <Route path="/private/document/:id" component={DocumentDetail} />
        <Route path="/private/profile" component={Profile} />
        <Route path="/private/videoconferences" component={Videoconferences} />
        <Route path="/private/videoconference-new" component={VideoconferenceNew} />
      </Switch>
    </HomeLayout>
  );
};

export default Home;
