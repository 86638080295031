import { Box } from '@material-ui/core';
import _ from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Alert, CompletedExchangeListItem, Filter, Loading, Message, PagedList } from '../../components';
import { IExchange } from '../../domain/Exchange';
import useStores from '../../hooks/useStores';

const Received = () => {
  const { exchangeHistoryStore, userStore, onboardingStore, tokenStore } = useStores();
  const [data, setData] = useState<IExchange[]>();

  useEffect(() => {
    !userStore.loaded && tokenStore.generate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userStore.loaded) {
      userStore.badges();
      exchangeHistoryStore.loadReceivedExchanges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.loaded]);

  useEffect(() => {
    if (exchangeHistoryStore.loaded && exchangeHistoryStore.received) {
      setData(exchangeHistoryStore.received);
    }
  }, [exchangeHistoryStore.loaded, exchangeHistoryStore.received]);

  if (!exchangeHistoryStore.loaded) {
    return <Loading />;
  }

  if (exchangeHistoryStore.received?.length === 0) {
    return (
      <Box>
        <Alert show={onboardingStore.verified} messageKey="onboarding.confirmation" />
        <Message logo="folder" title="received.empty" subtitle="received.hint" />
      </Box>
    );
  }

  const onFilterChange = (filter: any) => {
    if (!filter) {
      setData(exchangeHistoryStore.received);
      return;
    }

    let result = exchangeHistoryStore.received!;
    if (filter.notary?.value) {
      const filterValue = filter.notary.value.toLowerCase();
      const predicate = (x: IExchange) => x.from.name?.toLowerCase().includes(filterValue);

      result = _.filter(result, predicate);
    }

    if (filter.status) {
      result = _.filter(result, (x) => x.status === filter.status.value);
    }

    if (filter.period) {
      const from = moment(filter.period.value.from).subtract(1, 'days');
      const to = moment(filter.period.value.to).add(1, 'days');
      result = _.filter(result, (x) => moment(x.sentAt).isBetween(from, to, 'day'));
    }

    setData(result);
  };

  return (
    <Box>
      <Filter filterType="exchange" onFilterChange={onFilterChange} />
      <PagedList data={data} render={(item: IExchange) => <CompletedExchangeListItem key={item.id} exchange={item} mode="received" />} />
    </Box>
  );
};

export default observer(Received);
