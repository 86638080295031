import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Public, Unauthorized, Login, Home, Onboarding, NotSupported } from './pages';
import { Provider } from 'mobx-react';
import i18n from './locales/i18n';
import { store, initStore } from './stores/InitStore';
import ThemedLayout from './components/layouts/ThemedLayout';
import { CssBaseline } from '@material-ui/core';

const App = () => {
  initStore();
  i18n.init();
  return (
    <Provider {...store}>
      <ThemedLayout>
        <CssBaseline />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route path="/" exact={true} component={Public} />
            <Route path="/login" component={Login} />
            <Route path="/private" component={Home} />
            <Route path="/error/unauthorized" component={Unauthorized} />
            <Route path="/error/not-supported" component={NotSupported} />
            <Route path="/onboarding" component={Onboarding} />
          </Switch>
        </BrowserRouter>
      </ThemedLayout>
    </Provider>
  );
};

export default App;
