import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  check: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    marginBottom: theme.spacing(1)
  },
  status: {
    width: '12px',
    height: '12px',
    borderRadius: '6px'
  },
  text: {
    marginLeft: theme.spacing(3)
  }
}));

export default useStyles;
