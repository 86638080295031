import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    width: '200px'
  },
  primary: {
    textTransform: 'none',
    fontWeight: 600,
    width: '80px',
    marginLeft: theme.spacing(1)
  },
  secondary: {
    textTransform: 'none',
    fontWeight: 600,
    width: '80px',
    backgroundColor: '#999999',
    color: theme.palette.common.white
  },
  notAvailable: {
    fontSize: '12px',
    color: '#CCCCCC',
    fontWeight: 600
  },
  progress: {
    color: '#FFFFFF'
  }
}));

export default useStyles;
