import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  open: {
    fontWeight: 600,
    textTransform: 'none',
    width: '80px',
    height: '40px'
  },
  delete: {
    backgroundColor: '#999999',
    color: '#FFFFFF',
    fontWeight: 600,
    textTransform: 'none',
    width: '80px',
    marginRight: theme.spacing(1),
    height: '40px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  progress: {
    color: '#FFFFFF'
  }
}));

export default useStyles;
