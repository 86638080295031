import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  next: {
    textTransform: 'none',
    fontWeight: 600,
  },
  back: {
    marginRight: theme.spacing(2),
    textTransform: 'none',
    backgroundColor: '#999999',
    color: '#FFFFFF',
    fontWeight: 600
  }
}));

export default useStyles;
