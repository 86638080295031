import { INotary } from './Notary';

export enum VideoconferenceStatus {
  Scheduled = 'SCHEDULED',
  Pending = 'PENDING',
  Finished = 'FINISHED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export interface IVideoconferenceRecipient {
  notary: INotary;
  acceptedAt?: Date;
  rejectedAt?: Date;
}
export interface IVideoconference {
  id: number;
  subject: string;
  description: string;
  status: VideoconferenceStatus;
  duration: number;
  scheduledAt: Date;
  url: string;
  count?: number;
  from: INotary;
  recipients: IVideoconferenceRecipient[];
}

export interface ISaveVideoconference {
  subject: string;
  duration: number;
  scheduledAt: Date;
  description?: string;
  to: INotary[];
}
