import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  menu: {
    backgroundColor: theme.menu?.color,
    minHeight: '100vh',
    width: '260px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  eudoc: {
    display: 'block',
    margin: 'auto',
    width: '150px',
    marginTop: theme.spacing(5),
    content: `url(${process.env.PUBLIC_URL}/images/${theme.logos?.eudocPowered})`
  },
  options: {
    marginTop: theme.spacing(3),
    flex: 1,
    width: '100%',
    textAlign: 'left',
    verticalAlign: 'top'
  },
  logo: {
    marginBottom: theme.spacing(3)
  },
  sendNew: {
    fontSize: '18px',
    marginTop: theme.spacing(8),
    textTransform: 'none',
    fontWeight: 600
  },
  videoconferences: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.videoconferences?.menu,
    borderRadius: 8
  },
  videoconferenceText: {
    color: theme.videoconferences?.text,
    fontWeight: 600
  },
  videoconferenceNew: {
    marginTop: theme.spacing(2),
    width: '100%',
    textTransform: 'none',
    fontWeight: 600
  }
}));

export default useStyles;
