import React, { useEffect } from 'react';
import useStores from '../../hooks/useStores';
import { Feedback, Loading, Message } from '../../components';
import { Box, Button } from '@material-ui/core';
import useStyles from './Downloaded.styles';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

interface ParamTypes {
  id: string;
}

const Downloaded = () => {
  const { id } = useParams<ParamTypes>();
  const { exchangeDetailStore, userStore } = useStores();
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const onBack = () => history.goBack();
  const recipient = exchangeDetailStore.exchange?.recipients.find((r) => r.notary.notaryId === userStore.user?.id);

  useEffect(() => {
    exchangeDetailStore.downloadReceipt(+id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (exchangeDetailStore.receipt) {
    return <Loading />;
  }

  return (
    <Box>
      <Message logo="downloaded" title="exchanges.downloaded.title" subtitle="exchanges.downloaded.hint" />
      <Feedback id={recipient!.id} type="recipient" />
      <Box className={styles.actions}>
        <Button onClick={onBack} className={styles.back} variant="contained">
          <Trans t={t} i18nKey="exchanges.downloaded.back" />
        </Button>
      </Box>
    </Box>
  );
};

export default observer(Downloaded);
