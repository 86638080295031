import { Box, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '../../components';
import useStores from '../../hooks/useStores';
import { Documents, Header, History, Warning } from './components';
import useStyles from './ExchangeDetail.styles';

interface ParamTypes {
  id: string;
}

const ExchangeDetail = () => {
  const { id } = useParams<ParamTypes>();
  const { exchangeDetailStore } = useStores();
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    exchangeDetailStore.load(+id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!exchangeDetailStore.loaded || !exchangeDetailStore.exchange) {
    return <Loading />;
  }

  return (
    <Box>
      <Warning exchange={exchangeDetailStore.exchange} />
      <Header exchange={exchangeDetailStore.exchange} />
      <Box className={styles.description}>
        <Typography className={styles.descriptionTitle} variant="h5">
          <Trans t={t} i18nKey="exchanges.description" />
        </Typography>
        <Typography variant="body2">{exchangeDetailStore.exchange.description}</Typography>
      </Box>
      <Box className={styles.lower}>
        <Documents exchange={exchangeDetailStore.exchange} />
        <History exchange={exchangeDetailStore.exchange} />
      </Box>
      <Box className={styles.navigation}>
        <Button onClick={() => history.goBack()} className={styles.back} variant="contained">
          <Trans t={t} i18nKey="exchanges.actions.back" />
        </Button>
      </Box>
    </Box>
  );
};

export default observer(ExchangeDetail);
