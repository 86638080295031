import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px'
  },
  draft: {
    color: '#FFFFFF',
    fontWeight: 600,
    textTransform: 'none',
    marginRight: theme.spacing(2),
    height: '40px'
  },
  next: {
    textTransform: 'none',
    fontWeight: 600,
    '&:disabled': {
      color: 'white',
      height: '40px'
    },
    height: '40px'
  },
  back: {
    marginRight: theme.spacing(2),
    textTransform: 'none',
    backgroundColor: '#999999',
    color: '#FFFFFF',
    fontWeight: 600,
    height: '40px'
  },
  icon: {
    margin: theme.spacing(0, 1, 0, 1),
  }
}));

export default useStyles;
