import { Box, MenuItem, TextField, ThemeProvider, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStores from '../../../../hooks/useStores';
import Navigation from '../navigation/Navigation';
import { Controller, useForm } from 'react-hook-form';
import useStyles from './Details.styles';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import calendarTheme from '../../../../themes/calendarTheme';
import es from 'date-fns/locale/es';
import nl from 'date-fns/locale/nl';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import moment from 'moment';
import clsx from 'clsx';

const SelectDocuments = () => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const { addVideoconferenceStore } = useStores();
  const {
    handleSubmit,
    trigger,
    control,
    watch,
    setError,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  const videoconferenceDate = watch('date');
  useEffect(() => {
    if (moment(videoconferenceDate).isBefore(moment(new Date()), 'hour')) {
      setError('date', { type: 'required' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoconferenceDate]);

  const isNextDisabled = Object.keys(errors).length !== 0;

  const onNext = (data: { subject: string; date: string; duration: string; description: string }) => {
    addVideoconferenceStore.saveData(data.subject, data.date, data.duration, data.description);
  };

  const locale = () => {
    if (i18n.language?.startsWith('es')) {
      return es;
    } else if (i18n.language?.startsWith('de')) {
      return de;
    } else if (i18n.language?.startsWith('nl')) {
      return nl;
    }

    return en;
  };

  useEffect(() => {
    trigger();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box mt={3}>
        <Typography variant="h2">
          <Trans t={t} i18nKey="videoconferences.details.title" />
        </Typography>
      </Box>
      <Box mt={3} className={styles.row}>
        <Box style={{ flex: 1 }}>
          <Typography className={clsx(styles.label, styles.required)} variant="body2">
            <Trans t={t} i18nKey="videoconferences.details.fields.subject.label" />
          </Typography>
          <Controller
            name="subject"
            control={control}
            defaultValue={addVideoconferenceStore.subject}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                className={styles.text}
                placeholder={t('videoconferences.details.fields.subject.placeholder')}
                autoComplete="disabled"
                size="small"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
              />
            )}
          />
        </Box>
        <Box ml={3}>
          <Typography className={clsx(styles.label, styles.required)} variant="body2">
            <Trans t={t} i18nKey="videoconferences.details.fields.date.label" />
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale()}>
            <ThemeProvider theme={calendarTheme}>
              <Controller
                name="date"
                control={control}
                defaultValue={addVideoconferenceStore.scheduledAt ?? new Date()}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DateTimePicker
                    ampm={false}
                    disablePast={true}
                    format="dd/MM/yyyy HH:mm"
                    inputVariant="outlined"
                    margin="dense"
                    variant="inline"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                  />
                )}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Box>
        <Box ml={3}>
          <Typography className={clsx(styles.label, styles.required)} variant="body2">
            <Trans t={t} i18nKey="videoconferences.details.fields.duration.label" />
          </Typography>
          <Controller
            name="duration"
            control={control}
            defaultValue={addVideoconferenceStore.duration ?? ''}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                select={true}
                className={styles.text}
                autoComplete="disabled"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                margin="dense"
                style={{ width: '240px' }}
              >
                <MenuItem value="" disabled={true}>
                  <Trans t={t} i18nKey="videoconferences.details.fields.duration.placeholder" />
                </MenuItem>
                <MenuItem value="30">
                  <Trans t={t} i18nKey="videoconferences.details.fields.duration.options.thirtyMinutes" />
                </MenuItem>
                <MenuItem value="60">
                  <Trans t={t} i18nKey="videoconferences.details.fields.duration.options.sixtyMinutes" />
                </MenuItem>
                <MenuItem value="90">
                  <Trans t={t} i18nKey="videoconferences.details.fields.duration.options.ninetyMinutes" />
                </MenuItem>
              </TextField>
            )}
          />
        </Box>
      </Box>
      <Box mt={3}>
        <Typography className={styles.label} variant="body2">
          <Trans t={t} i18nKey="videoconferences.details.fields.description.label" />
        </Typography>
        <Controller
          name="description"
          control={control}
          defaultValue={addVideoconferenceStore.description}
          render={({ field: { onChange, value } }) => (
            <TextField
              rows={3}
              multiline={true}
              className={styles.text}
              placeholder={t('videoconferences.details.fields.description.placeholder')}
              autoComplete="disabled"
              size="small"
              variant="outlined"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Box>
      <Navigation shouldDisplayBack={true} shouldDisplayNext={true} isNextDisabled={isNextDisabled} onNext={handleSubmit(onNext)} />
    </>
  );
};

export default observer(SelectDocuments);
