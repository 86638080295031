import _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { IVideoconference } from '../domain/Videoconference';
import { get, put } from '../services/http.service';

class VideoconferenceStore {
  loaded = false;
  error = false;

  videoconferences?: IVideoconference[];

  constructor() {
    makeAutoObservable(this);
  }

  async load() {
    try {
      this.loaded = false;

      const { data } = await get<IVideoconference[]>('/videoconferences/list');
      runInAction(() => {
        this.loaded = true;
        this.videoconferences = data;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  async start(id: number): Promise<string | undefined> {
    const result = await this.invoke(id, 'start');
    return result?.url;
  }

  async cancel(id: number): Promise<IVideoconference | null> {
    return this.invoke(id, 'cancel');
  }

  async accept(id: number): Promise<IVideoconference | null> {
    return this.invoke(id, 'accept');
  }

  async reject(id: number): Promise<IVideoconference | null> {
    return this.invoke(id, 'reject');
  }

  async invoke(id: number, action: 'start' | 'reject' | 'accept' | 'cancel'): Promise<IVideoconference | null> {
    try {
      const { data } = await put<void, IVideoconference>(`/videoconferences/${id}/${action}`);
      const videoconferenceIndex = _.findIndex(this.videoconferences, (v) => v.id === id);
      runInAction(() => {
        this.videoconferences![videoconferenceIndex] = data;
      });

      return data;
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }

    return null;
  }
}

export default VideoconferenceStore;
