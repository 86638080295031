import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2, 0),
    backgroundColor: '#737373',
    borderRadius: '4px',
    height: '32px'
  },
  item: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid #FFFFFF',
    color: '#FFFFFF',
    cursor: 'pointer'
  },
  icon: {
    cursor: 'pointer'
  },
  reset: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  resetText: {
    color: '#FFFFFF',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  optionContainer: {
    backgroundColor: '#79A6D2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '4px'
  },
  option: {
    color: '#FFFFFF',
    fontWeight: 600,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1)
  }
}));

export default useStyles;
