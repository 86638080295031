import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './FilteredOption.styles';

interface FilteredOptionProps {
  name: string;
  value: any;
  onRemove: (name: string) => void;
}

const FilteredOption = ({ name, value, onRemove }: FilteredOptionProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  if (!value || !value.value) {
    return null;
  }

  const suffix = () => {
    if (value.value === 'true') {
      return null;
    }

    return <>: <Trans t={t} i18nKey={value.text} /></>;
  }

  return (
    <Box className={styles.container}>
      <Typography variant="body2" className={styles.option}>
        <Trans t={t} i18nKey={`filter.${name}`} />
        {suffix()}
      </Typography>
      <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/ic_close_white.svg`} alt="" onClick={() => onRemove(name)} />
    </Box>
  );
}

export default FilteredOption;
