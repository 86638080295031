import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  box: {
    width: '240px',
    marginTop: theme.spacing(3)
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    width: '100%'
  },
  label: {
    marginBottom: theme.spacing(1),
    textAlign: 'left'
  },
  text: {
    width: '100%'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3, 0)
  },
  back: {
    width: '100px',
    textTransform: 'none',
    color: theme.exchange?.header.color,
    borderColor: theme.exchange?.header.color,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.exchange?.header.color
    }
  },
  send: {
    textTransform: 'none',
    fontWeight: 600,
    width: '100px'
  },
  resend: {
    marginTop: theme.spacing(1),
    color: '#01A4DA',
    cursor: 'pointer'
  },
  progress: {
    color: theme.palette.primary.main
  },
  confirmProgress: {
    color: 'white'
  },
  title: {
    textAlign: 'center'
  },
  info: {
    marginTop: theme.spacing(3),
    textAlign: 'center'
  }
}));

export default useStyles;
