import { Box, Tooltip, Typography, Collapse } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { IExchange } from '../../domain/Exchange';
import useStyles from './ExchangeListItem.styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ExchangeStatusBadge from '../exchangeStatusBadge/ExchangeStatusBadge';
import { getTitle } from '../../services/exchangeService';

interface ExchangeListItemProps {
  exchange: IExchange;
  mode: 'received' | 'sent' | 'draft';
  children: ReactNode;
}

const ExchangeListItem = ({ exchange, mode, children }: ExchangeListItemProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);

  const date = () => {
    if (mode === 'draft') {
      return exchange.updatedAt;
    }
    return exchange.sentAt;
  };

  const handleCollapse = () => {
    setCollapsed(prev => !prev);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.logo}>
      <Tooltip title={getTitle(exchange, t)} placement="top-start">
        <img alt="" src={`${process.env.PUBLIC_URL}/icons/exchange/${exchange.type?.toLowerCase() ?? 'document'}.svg`} />
        </Tooltip>
      </Box>
      <Box className={styles.content}>
        <Box className={styles.header}>
          <Box className={styles.title}>
            <Typography variant="h3" className={styles.titleText}>
              {getTitle(exchange, t)}
              {exchange.description ? 
                <img onClick={handleCollapse} className={collapsed ? styles.arrowIcon : styles.arrowIconUp}  src={`${process.env.PUBLIC_URL}/icons/ic_arrow_down.svg`} alt="" />
                : null}
            </Typography>
            <Box className={styles.info}>
              <ExchangeStatusBadge exchange={exchange} />
              <Typography variant="body2">{moment.utc(date()).local().format('DD/MM/yyyy HH:mm')}</Typography>
            </Box>
          </Box>
          {mode === 'received' && (
            <Box className={styles.notary}>
              <Typography variant="h4" className={styles.notaryText}>
                {exchange.from.name.toLowerCase()}
              </Typography>
              <Typography variant="body2" className={styles.notaryLocation}>{`${exchange.from.city}, ${t(
                'countries.' + exchange.from.country.toLowerCase()
              )}`}</Typography>
            </Box>
          )}
          {mode !== 'received' &&
            exchange.recipients.map((recipient) => (
              <Box key={recipient.notary.notaryId} className={styles.notary}>
                <Typography variant="h4" className={styles.notaryText}>
                  {recipient.notary.name.toLowerCase()}
                </Typography>
                <Typography variant="body2" className={styles.notaryLocation}>{`${recipient.notary.city}, ${t(
                  'countries.' + recipient.notary.country.toLowerCase()
                )}`}</Typography>
              </Box>
            ))}
          <Box className={styles.actions}>{children}</Box>
        </Box>
        <Collapse in={!collapsed}>
          <Typography variant="body2" className={styles.description}>
            {exchange.description}
          </Typography>
        </Collapse>
      </Box>
    </Box>
  );
};

export default ExchangeListItem;
