import { makeStyles, Theme } from '@material-ui/core/styles';

type OptionStylesProps = {
  menu: boolean;
};

const useStyles = makeStyles<Theme, OptionStylesProps>((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 1, 1, 0),
    marginTop: theme.spacing(1)
  },
  noSelected: {
    paddingLeft: ({ menu }) => (menu ? theme.spacing(1) : 0),
    fontSize: 18,
    color: theme.palette.text.primary
  },
  selected: {
    paddingLeft: ({ menu }) => (menu ? theme.spacing(1) : 0),
    fontSize: 18,
    color: '#01CBF4'
  },
  line: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    width: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  space: {
    width: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  badges: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 600
  }
}));

export default useStyles;
