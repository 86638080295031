import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    padding: theme.spacing(3, 3, 4, 3),
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '128px'
  },
  item: {
    marginLeft: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  button: {
    color: '#FFFFFF',
    fontWeight: 600,
    textTransform: 'none',
    height: '32px',
    width: '122px'
  },
  textField: {
    height: '32px'
  }
}));

export default useStyles;
