import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import useStores from '../../../../hooks/useStores';
import useStyles from './UserOptions.styles';
import PopperMenu, { ProperMenuHandle } from '../../../popperMenu/PopperMenu';

const UserOptions = () => {
  const { userStore } = useStores();
  const history = useHistory();
  const styles = useStyles();

  const ref = useRef<ProperMenuHandle>(null);
  const options = [
    { key: 'options.edit', value: '/private/profile' },
    { key: 'options.logout', value: '/' }
  ];

  const handleOpen = () => {
    ref.current?.open();
  };

  const handleSelect = (route: string) => {
    history.push(route);
  };

  return (
    <Box className={styles.container}>
      <Typography variant="body2" className={styles.username}>
        {userStore.name}
      </Typography>
      <PopperMenu options={options} menuRef={ref} onSelected={handleSelect}>
        <Box className={styles.initials} onClick={handleOpen}>
          <Typography variant="h5" className={styles.text}>
            {userStore.initials}
          </Typography>
        </Box>
      </PopperMenu>
    </Box>
  );
};

export default observer(UserOptions);
