import { makeAutoObservable, runInAction } from 'mobx';
import { INotary, ISearchNotary, ISearchResult } from '../domain/Notary';
import { get, post } from '../services/http.service';

class NotaryDirectoryStore {
  readonly size = 5;

  loading = false;
  error = false;
  initialized = false;
  hasToken = false;

  total?: number;
  notaries?: INotary[];
  config?: ISearchNotary;

  constructor() {
    makeAutoObservable(this);
  }

  get isEmpty() {
    return !this.isLoading && !this.notaries?.length;
  }

  get isLoading() {
    return this.loading;
  }

  clear() {
    runInAction(() => {
      this.initialized = false;
      this.notaries = [];
      this.total = undefined;
    });
  }

  reset() {
    runInAction(() => {
      this.notaries = [];
      this.total = undefined;
    });
  }

  configure(newConfig: ISearchNotary) {
    runInAction(() => {
      this.reset();
      this.config = newConfig;
    });
  }

  async getToken() {
    try {
      await get<any>('/notaries/edn/token');
      runInAction(() => {
        this.hasToken = true;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  async search(page: number, callback: (data: any[]) => void) {
    try {
      if (!this.config) {
        console.error('You must configure the service before');
        return;
      }

      this.initialized = true;
      this.loading = true;
      this.notaries = undefined;

      const start = page * this.size;
      const { data } = await post<ISearchNotary, ISearchResult>(`/notaries/edn/search?start=${start}`, this.config, {});

      runInAction(() => {
        this.loading = false;
        this.total = data.total;
        this.notaries = data.items;
        callback(data.items);
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        this.error = true;
      });
    }
  }

  async details(directoryId: number) {
    const { data } = await get<INotary>(`/notaries/edn/details/${directoryId}`, {});

    return data;
  }
}
export default NotaryDirectoryStore;
