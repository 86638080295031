import { AxiosRequestConfig } from 'axios';

export const authInterceptor = (request: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  const userToken = localStorage.getItem('x-token');
  if (userToken) {
    request.headers['X-Token'] = userToken;
  }

  return request;
};
