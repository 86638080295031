import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  logo: {
    display: 'block',
    margin: 'auto',
    width: '200px',
    marginTop: theme.spacing(8),
    content: `url(${process.env.PUBLIC_URL}/images/${theme.logos?.eudoc})`
  },
  box: {
    width: '70%'
  },
  title: {
    margin: theme.spacing(3, 0),
    textAlign: 'center'
  },
  info: {
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  later: {
    textTransform: 'none',
    color: theme.exchange?.header.color,
    borderWidth: 0,
    marginBottom: theme.spacing(5),
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderWidth: 0
    }
  }
}));

export default useStyles;
