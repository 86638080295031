import React from 'react';
import useStores from '../../../../hooks/useStores';
import { DocumentViewer } from '../../../../components';

const ViewDocument = () => {
  const { documentDetailStore } = useStores();
  return <DocumentViewer document={documentDetailStore.document} onBack={() => documentDetailStore.reset()} />;
};

export default ViewDocument;
