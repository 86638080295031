import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(1)
  },
  required: {
    '&:after': {
      content: '"*"',
      color: '#F5A524'
    }
  },
  text: {
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

export default useStyles;
