import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3)
  },
  info: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  infoColumn: {
    flex: 2,
    padding: theme.spacing(1)
  },
  infoText: {
    marginLeft: theme.spacing(1)
  },
  column: {
    flex: 1,
    padding: theme.spacing(1)
  },
  label: {
    marginBottom: theme.spacing(1)
  },
  text: {
    width: '100%',
    backgroundColor: '#F5F5F5'
  },
  notificationRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  notificationEmailRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2)
  },
  notificationEmailText: {
    width: '100%'
  },
  legalRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1)
  },
  link: {
    color: '#01A4DA'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3, 0)
  },
  send: {
    textTransform: 'none',
    width: '100px',
    fontWeight: 600,
    marginLeft: theme.spacing(1)
  },
  progress: {
    color: '#FFFFFF'
  },
  back: {
    width: '100px',
    textTransform: 'none',
    color: theme.exchange?.header.color,
    borderColor: theme.exchange?.header.color,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.exchange?.header.color
    }
  }
}));

export default useStyles;
