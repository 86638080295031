import { makeAutoObservable, runInAction } from 'mobx';
import { IExchange } from '../domain/Exchange';
import { get, download } from '../services/http.service';

class ExchangeDetailStore {
  error = false;
  loaded = false;
  downloading = false;
  receipt = false;
  exchange?: IExchange = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async load(id: number) {
    try {
      this.loaded = false;
      const { data } = await get<IExchange>(`/exchanges/details/${id}`);
      runInAction(() => {
        this.loaded = true;
        this.exchange = data;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  async downloadAll(id: number) {
    try {
      this.downloading = true;

      await download(`/exchanges/download/${id}`, `documents-exchange-${id}.zip`);
      const { data } = await get<IExchange>(`/exchanges/details/${id}`);

      runInAction(async () => {
        this.exchange = data;
        this.downloading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }

  async downloadReceipt(id: number) {
    try {
      this.receipt = true;
      await download(`/exchanges/receipt/${id}`, `receipt-${id}.pdf`);
      runInAction(async () => {
        this.receipt = false;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
  }
}
export default ExchangeDetailStore;
