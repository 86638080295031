import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3)
  },
  option: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    fontWeight: 600
  },
  separator: {
    color: '#999999',
    margin: theme.spacing(0, 1)
  }
}));

export default useStyles;
