import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IExchange } from '../../../../domain/Exchange';
import useStyles from './History.styles';

interface HistoryProps {
  exchange: IExchange;
}

const History = ({ exchange }: HistoryProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const height = exchange.recipients.length * 40;

  return (
    <Box className={styles.container}>
      <Typography className={styles.title} variant="h3">
        <Trans t={t} i18nKey="exchanges.history.title" />
      </Typography>
      <Box className={styles.history}>
        <Box className={styles.item}>
          <Box className={styles.numbers}>
            <Box className={styles.number}>
              <Typography className={styles.text} variant="h6">
                1
              </Typography>
            </Box>
            <Box style={{ height: '40px' }} className={styles.separator} />
          </Box>
          <Box className={styles.data}>
            <Typography className={styles.action} variant="h4">
              <Trans t={t} i18nKey="exchanges.history.sent" />
            </Typography>
            <Typography className={styles.name} variant="h5">
              {exchange.from.name.toLowerCase()}
            </Typography>
            <Box className={styles.time}>
              <img alt="" src={`${process.env.PUBLIC_URL}/icons/ic_time.svg`} />
              <Typography className={styles.timestap} variant="h5">
                {moment.utc(exchange.sentAt).local().format('DD/MM/yyyy HH:mm')}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={styles.item}>
          <Box className={styles.numbers}>
            <Box className={styles.number}>
              <Typography className={styles.text} variant="h6">
                2
              </Typography>
            </Box>
            <Box style={{ height: `${height}px` }} className={styles.separator} />
          </Box>
          <Box className={styles.data}>
            <Typography className={styles.action} variant="h4">
              <Trans t={t} i18nKey="exchanges.history.received" />
            </Typography>
            {exchange.recipients.map((recipient) => (
              <Box key={recipient.notary.notaryId}>
                <Typography className={styles.name} variant="h5">
                  {recipient.notary.name.toLowerCase()}
                </Typography>
                <Box className={styles.time}>
                  <img alt="" src={`${process.env.PUBLIC_URL}/icons/ic_time.svg`} />
                  <Typography className={styles.timestap} variant="h5">
                    {recipient.receivedAt ? moment.utc(recipient.receivedAt).local().format('DD/MM/yyyy HH:mm') : '--'}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box className={styles.item}>
          <Box className={styles.numbers}>
            <Box className={styles.number}>
              <Typography className={styles.text} variant="h6">
                3
              </Typography>
            </Box>
          </Box>
          <Box className={styles.data}>
            <Typography className={styles.action} variant="h4">
              <Trans t={t} i18nKey="exchanges.history.download" />
            </Typography>
            {exchange.recipients.map((recipient) => (
              <Box key={recipient.notary.notaryId}>
                <Typography className={styles.name} variant="h5">
                  {recipient.notary.name.toLowerCase()}
                </Typography>
                <Box className={styles.time}>
                  <img alt="" src={`${process.env.PUBLIC_URL}/icons/ic_time.svg`} />
                  <Typography className={styles.timestap} variant="h5">
                    {recipient.downloadedAt ? moment.utc(recipient.downloadedAt).local().format('DD/MM/yyyy HH:mm') : '--'}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default History;
