import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './ValidationItem.styles';
import { useTranslation } from 'react-i18next';
import { DocumentStatus } from '../../domain/Document';

interface ValidationItemProps {
  status: DocumentStatus;
  check?: boolean;
  text: string;
}

const ValidationItem = ({ status, check, text }: ValidationItemProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const color = () => {
    if (status === DocumentStatus.Verified) {
      return '#6EB91D';
    }

    if (status === DocumentStatus.Pending) {
      return '#F5A524';
    }

    return check ? '#6EB91D' : '#CC001B';
  };

  return (
    <Box className={styles.check}>
      <Box style={{ backgroundColor: color() }} className={styles.status}></Box>
      <Typography className={styles.text} variant="body2">
        {t(`validation.${text}`)}
      </Typography>
    </Box>
  );
};

export default ValidationItem;
