import { Box, Button, ThemeProvider, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import PopoverFilter, { PopoverFilterHandle } from '../popoverFilter/PopoverFilter';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import useStyles from './PeriodFilter.styles';
import calendarTheme from '../../../../themes/calendarTheme';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import es from 'date-fns/locale/es';
import nl from 'date-fns/locale/nl';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';

interface PeriodFilterProps {
  onPeriodChange: (value: any) => void;
}

const PeriodFilter = ({ onPeriodChange }: PeriodFilterProps) => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const popoverRef = useRef<PopoverFilterHandle>(null);
  const [dateFrom, setDateFrom] = useState<Date | null>(moment().subtract(7, 'days').toDate());
  const [dateTo, setDateTo] = useState<Date | null>(new Date());

  const handleChangeFrom = (value: Date | null) => {
    setDateFrom(value);
  };

  const handleChangeTo = (value: Date | null) => {
    setDateTo(value);
  };

  const handleApply = (event: React.MouseEvent<HTMLElement>) => {
    const text = `${moment(dateFrom).format('DD/MM/yyyy')} - ${moment(dateTo).format('DD/MM/yyyy')}`;
    onPeriodChange({ period: { text: text, value: { from: dateFrom, to: dateTo } } });
    popoverRef.current?.close();

    event.stopPropagation();
  };

  const locale = () => {
    if (i18n.language?.startsWith('es')) {
      return es;
    } else if (i18n.language?.startsWith('de')) {
      return de;
    } else if (i18n.language?.startsWith('nl')) {
      return nl;
    }

    return en;
  };

  return (
    <PopoverFilter ref={popoverRef} textKey="filter.period">
      <Box className={styles.container}>
        <Box className={styles.left}>
          <Typography variant="h6" className={styles.text}>
            <Trans i18nKey="filter.dates.from" />
            <span style={{ color: '#F5A524' }}>*</span>
          </Typography>
          <Box className={styles.calendar} mt={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale()}>
              <ThemeProvider theme={calendarTheme}>
                <DatePicker readOnly={true} orientation="portrait" variant="static" value={dateFrom} onChange={handleChangeFrom} />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
        <Box className={styles.right}>
          <Typography variant="h6" className={styles.text}>
            <Trans i18nKey="filter.dates.to" />
            <span style={{ color: '#F5A524' }}>*</span>
          </Typography>
          <Box className={styles.calendar} mt={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale()}>
              <ThemeProvider theme={calendarTheme}>
                <DatePicker
                  readOnly={true}
                  orientation="portrait"
                  variant="static"
                  openTo="date"
                  value={dateTo}
                  onChange={handleChangeTo}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
      </Box>
      <Box className={styles.actions}>
        <Button onClick={handleApply} className={styles.apply} variant="contained" color="primary">
          <Trans t={t} i18nKey="filter.actions.apply" />
        </Button>
      </Box>
    </PopoverFilter>
  );
};

export default PeriodFilter;
