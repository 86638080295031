import OptionsFilter from '../optionsFilter/OptionsFilter';

interface StatusFilterVideoProps {
  onStatusChange: (value?: any) => void;
}

const StatusVideoconferenceFilter = ({ onStatusChange }: StatusFilterVideoProps) => {
  const statusesVideo = [
    { key: 'videoconferences.status.scheduled', value: 'SCHEDULED' },
    { key: 'videoconferences.status.pending', value: 'PENDING' },
    { key: 'videoconferences.status.finished', value: 'FINISHED' },
    { key: 'videoconferences.status.rejected', value: 'REJECTED' },
    { key: 'videoconferences.status.cancelled', value: 'CANCELLED' }
  ];

  const onSelectExchangeStatusVideo = (status: string) => {
    const exchangeStatus = statusesVideo.find((x) => x.value === status)?.key;
    const newFilter = { status: { text: exchangeStatus, value: status } };
    onStatusChange(newFilter);
  };

  return <OptionsFilter textKey="filter.status" options={statusesVideo} onSelect={onSelectExchangeStatusVideo} />;
};

export default StatusVideoconferenceFilter;
