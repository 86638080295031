import { makeStyles } from '@material-ui/core/styles';

const useLoadingStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(8)
  }
}));

export default useLoadingStyles;
