import React, { ReactNode, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './HomeLayout.styles';
import { Option, UserMenu, Breadcrumbs } from './components';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import useStores from '../../hooks/useStores';
import { IOption } from '../../domain/Menu';

interface HomeLayoutProps {
  children: ReactNode;
}

const HomeLayout = ({ children }: HomeLayoutProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  const { userStore, themeStore } = useStores();

  useEffect(() => {
    userStore.badges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userStore.userBadges) {
    return null;
  }

  const videoconferenceOption = {
    key: 'menu.videoconferences',
    route: '/private/videoconferences',
    badge: userStore.userBadges.videoconferences
  };

  const options: IOption[] = [
    { key: 'menu.received', route: '/private/received', badge: userStore.userBadges.received },
    { key: 'menu.draft', route: '/private/draft', badge: userStore.userBadges.draft },
    { key: 'menu.sent', route: '/private/sent', badge: userStore.userBadges.sent },
    { key: 'menu.notifications', route: '/private/notifications', badge: userStore.userBadges.notifications },
    { key: 'menu.exchange-new', route: '/private/exchange-new', hide: true },
    { key: 'menu.videoconference-new', route: '/private/videoconference-new', hide: true },
    { ...videoconferenceOption, hide: true }
  ];

  return (
    <Box className={styles.root}>
      <Box className={styles.menu}>
        <img className={styles.eudoc} alt="" />
        <Button className={styles.sendNew} variant="contained" color="primary" onClick={() => history.push('/private/exchange-new')}>
          {t('menu.exchange-new')}
        </Button>
        <Box className={styles.options}>
          {options.map((item) => item.hide || <Option key={item.key} option={item} menu={true} />)}
          <Box className={styles.videoconferences}>
            <Option option={videoconferenceOption} menu={false} />
            <Typography variant="h6" className={styles.videoconferenceText}>
              <Trans t={t} i18nKey="videoconferences.menu" />
            </Typography>
            <Button
              className={styles.videoconferenceNew}
              variant="contained"
              color="primary"
              onClick={() => history.push('/private/videoconference-new')}
            >
              {t('menu.videoconference-new')}
            </Button>
          </Box>
        </Box>
        <img className={styles.logo} src={`${process.env.PUBLIC_URL}/images/${themeStore.theme?.logos?.cnue}`} alt="" />
      </Box>
      <Box className={styles.content}>
        <UserMenu />
        <Breadcrumbs options={options} />
        <Box ml={3} mr={3} mt={3}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default observer(HomeLayout);
