import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { INotary } from '../domain/Notary';
import { ISaveVideoconference, IVideoconference } from '../domain/Videoconference';
import { post } from '../services/http.service';

class AddVideoconferenceStore {
  private readonly _maxSteps = 2;

  readonly options = [
    { number: 1, text: 'videoconferences.progress.notary' },
    { number: 2, text: 'videoconferences.progress.details' }
  ];

  index = 0;

  id = 0;
  notaries?: INotary[] = [];
  subject?: string;
  description?: string;
  duration?: number;
  scheduledAt?: Date;

  error = false;
  loaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  clear() {
    runInAction(() => {
      this.id = 0;
      this.subject = undefined;
      this.description = undefined;
      this.duration = undefined;
      this.scheduledAt = undefined;
      this.notaries = [];

      this.error = false;
      this.index = 0;
    });
  }

  addNotary(notary: INotary) {
    runInAction(() => {
      this.notaries?.push(notary);
    });
  }

  removeNotary(notary: INotary) {
    runInAction(() => {
      this.notaries?.splice(this.notaries?.indexOf(notary), 1);
    });
  }

  next() {
    runInAction(() => {
      this.index = Math.min(this.index + 1, this._maxSteps);
    });
  }

  back() {
    runInAction(() => {
      this.index = Math.max(this.index - 1, 0);
      this.error = false;
    });
  }

  saveData(subject: string, date: string, duration: string, description?: string) {
    runInAction(() => {
      this.subject = subject;
      this.scheduledAt = moment(date).utc().toDate();
      this.duration = parseInt(duration);
      this.description = description;
    });
  }

  end() {
    runInAction(() => {
      this.index = 0;
    });
  }

  async save() {
    try {
      this.loaded = false;

      const saveVideoconference = {
        id: this.id,
        subject: this.subject!,
        duration: this.duration!,
        scheduledAt: this.scheduledAt!,
        description: this.description,
        to: this.notaries!
      };

      const { data } = await post<ISaveVideoconference, IVideoconference>('/videoconferences/save', saveVideoconference, {});

      runInAction(() => {
        this.id = data.id;
        this.loaded = true;
        this.error = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loaded = true;
        this.error = true;
      });
    }
  }
}
export default AddVideoconferenceStore;
