import { Box, Typography, Collapse } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from './VideoconferenceItem.styles';
import { IVideoconference } from '../../../../domain/Videoconference';
import StatusBadge from '../statusBadge/StatusBadge';
import Actions from '../actions/Actions';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import RecipientList from '../recipientList/RecipientList';

interface VideoconferenceItemProps {
  videoconference: IVideoconference;
}

const VideoconferenceItem = ({ videoconference }: VideoconferenceItemProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [item, setItem] = useState(videoconference);
  const [collapsed, setCollapsed] = useState(true);

  const onChange = (data: IVideoconference | null) => {
    if (!data) {
      console.error('There was an error invoking videoconference action');
      return;
    }

    setItem(data);
  };

  const handleCollapse = () => {
    setCollapsed(prev => !prev);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Box className={styles.header}>
          <Box className={styles.subject}>
            <Typography variant="h3" className={styles.text}>
              {item.subject}
              {item.description ? 
                <img onClick={handleCollapse} className={collapsed ? styles.arrowIcon : styles.arrowIconUp} src={`${process.env.PUBLIC_URL}/icons/ic_arrow_down.svg`} alt="" />
                : null}
            </Typography>
            <Box className={styles.info}>
              <StatusBadge videoconference={item} />
              <Typography variant="body2">{moment.utc(item.scheduledAt).local().format('DD/MM/yyyy HH:mm')}</Typography>
            </Box>
          </Box>
          <Box className={styles.item}>
            <Typography variant="h5" className={styles.title}>
              <Trans t={t} i18nKey="videoconferences.host" />
            </Typography>
            <Typography variant="h4" className={styles.value}>
              {item.from.name.toLowerCase()}
            </Typography>
          </Box>
          <Box className={styles.item}>
            <Typography variant="h5" className={styles.title}>
              <Trans t={t} i18nKey="videoconferences.guest" />
            </Typography>
            <RecipientList videconference={item} />
          </Box>
          <Actions videoconference={item} onChange={onChange} />
        </Box>
        <Collapse in={!collapsed}>
          <Typography variant="body2" className={styles.description}>
            {item.description}
          </Typography>
        </Collapse>
      </Box>
    </Box>
  );
};

export default VideoconferenceItem;
