import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from './Warning.styles';
import { Trans, useTranslation } from 'react-i18next';
import { ExchangeStatus, IExchange } from '../../../../domain/Exchange';
import useStores from '../../../../hooks/useStores';

interface WarningProps {
  exchange: IExchange;
}

const Warning = ({ exchange }: WarningProps) => {
  const { exchangeDetailStore, userStore } = useStores();
  const styles = useStyles();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);

  if (!exchangeDetailStore.exchange || exchangeDetailStore.exchange.status === ExchangeStatus.Archived) {
    return null;
  }

  if (userStore.user?.id === exchange.from.notaryId) {
    return null;
  }

  if (!visible) {
    return null;
  }

  return (
    <Box className={styles.container}>
      <img alt="" src={`${process.env.PUBLIC_URL}/icons/ic_warning.svg`} />
      <Typography className={styles.text} variant="body2">
        <Trans t={t} i18nKey="exchanges.downloaded.expiration" values={{ days: exchangeDetailStore.exchange.expiresIn }} />
      </Typography>
      <img className={styles.close} src={`${process.env.PUBLIC_URL}/icons/ic_close.svg`} alt="" onClick={() => setVisible(false)} />
    </Box>
  );
};

export default Warning;
