import React, { useEffect } from 'react';
import useStores from '../../../../hooks/useStores';
import { Box, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Navigation from '../navigation/Navigation';
import { observer } from 'mobx-react';
import { Loading, RecipientListItem, SearchNotaries } from '../../../../components';
import { INotary } from '../../../../domain/Notary';

const SelectNotary = () => {
  const { t } = useTranslation();
  const { notaryDirectoryStore, exchangeStore } = useStores();
  const shouldDisplayDraft = !exchangeStore.notaries || exchangeStore.notaries.length > 0;
  const isNextDisabled = exchangeStore.notaries?.length === 0;

  const onRemove = (notary: INotary) => {
    exchangeStore.removeNotary(notary);
  };

  useEffect(() => {
    if (!notaryDirectoryStore.hasToken) {
      notaryDirectoryStore.getToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!notaryDirectoryStore.hasToken) {
    return <Loading />;
  }

  return (
    <>
      <SearchNotaries
        showTitle={true}
        showSearch={true}
        hintKey="search.empty.hint"
        current={exchangeStore.notaries}
        onSelect={(selected) => exchangeStore.addNotary(selected)}
      />
      {exchangeStore.notaries && exchangeStore.notaries.length > 0 && (
        <Box mt={8}>
          <Typography variant="h2">
            <Trans t={t} i18nKey="search.recipients" />
          </Typography>
          <Box mt={2}>
            {exchangeStore.notaries?.map((notary) => (
              <RecipientListItem key={notary.notaryId} notary={notary} onRemove={onRemove} />
            ))}
          </Box>
        </Box>
      )}
      <Navigation shouldDisplayNext={true} shouldDisplayDraft={shouldDisplayDraft} isNextDisabled={isNextDisabled} />
    </>
  );
};

export default observer(SelectNotary);
