import React from 'react';
import useStyles from './Navigation.styles';
import { Box, Button } from '@material-ui/core';
import useStores from '../../../../hooks/useStores';
import { Trans, useTranslation } from 'react-i18next';

interface NavigationProps {
  shouldDisplayBack?: boolean;
  shouldDisplayNext?: boolean;
  isNextDisabled?: boolean;
  nextTextKey?: string;
  onNext?: () => void;
}

const Navigation = ({ shouldDisplayBack, shouldDisplayNext, isNextDisabled, nextTextKey, onNext }: NavigationProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { addVideoconferenceStore } = useStores();

  const handleNext = () => {
    onNext && onNext();

    setTimeout(() => addVideoconferenceStore.next(), 500);
  };

  const handleBack = () => {
    addVideoconferenceStore.back();
  };

  return (
    <Box mt={2} mb={5} className={styles.actions}>
      {shouldDisplayBack && (
        <Button onClick={handleBack} className={styles.back} variant="contained">
          {'<'} <Trans t={t} i18nKey="navigation.back" />
        </Button>
      )}
      {shouldDisplayNext && (
        <Button onClick={handleNext} disabled={isNextDisabled} className={styles.next} variant="contained" color="primary">
          <Trans t={t} i18nKey={nextTextKey ?? 'navigation.next'} /> {'>'}
        </Button>
      )}
    </Box>
  );
};

export default Navigation;
