import React, { useEffect, useState } from 'react';
import { ISaveUser } from '../../domain/User';
import useStores from '../../hooks/useStores';
import { observer } from 'mobx-react';
import { Box, Button, CircularProgress, Link, TextField, Typography } from '@material-ui/core';
import useStyles from './Profile.styles';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import i18n from '../../locales/i18n';
import { Alert } from '../../components';

const Profile = () => {
  const { userStore } = useStores();
  const styles = useStyles();
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue
  } = useForm({ mode: 'onChange' });

  const languageKey = () => {
    let language = 'en';
    let languages = ['es', 'de'];
    languages.forEach((l) => {
      if (i18n.language?.startsWith(l)) {
        language = l;
      }
    });

    return language;
  };

  const onSubmit = (data: ISaveUser) => {
    userStore.update(data, () => setShowAlert(true));
  };

  useEffect(() => {
    if (userStore.user) {
      setValue('id', userStore.user.id.toString());
      trigger();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.user]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => setShowAlert(false), 3000);
    }

  }, [showAlert]);

  if (!userStore.user) {
    return null;
  }

  return (
    <Box>
      <Alert show={showAlert} messageKey={'profile.message'} />
      <Box className={styles.container}>
        <Box className={styles.box}>
          <Box className={styles.initials}>
            <Typography variant="h1" className={styles.initialsText}>
              {userStore.initials}
            </Typography>
          </Box>
          <Typography className={styles.title} variant="h2">
            {userStore.name}
          </Typography>
          <Box className={styles.row}>
            <Box className={styles.column}>
              <Typography className={styles.label} variant="body2">
                <Trans t={t} i18nKey="profile.name" />
              </Typography>
              <TextField className={styles.text} variant="outlined" size="small" disabled={true} value={userStore.user?.firstname} />
            </Box>
            <Box className={styles.column}>
              <Typography className={styles.label} variant="body2">
                <Trans t={t} i18nKey="profile.surname" />
              </Typography>
              <TextField className={styles.text} variant="outlined" size="small" disabled={true} value={userStore.user?.lastname} />
            </Box>
          </Box>
          <Box className={styles.row}>
            <Box className={styles.column}>
              <Typography className={styles.label} variant="body2">
                <Trans t={t} i18nKey="profile.id" />
              </Typography>
              <TextField className={styles.text} variant="outlined" size="small" disabled={true} value={userStore.user?.identificationNumber} />
            </Box>
            <Box className={styles.column}>
              <Typography className={styles.label} variant="body2">
                <Trans t={t} i18nKey="profile.email" />
                <span style={{ color: '#F5A524' }}> *</span>
              </Typography>
              <Controller
                name="notificationEmail"
                control={control}
                defaultValue={userStore.user?.notificationEmail}
                rules={{ required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    className={styles.input}
                    autoComplete="email"
                    size="small"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                  />
                )}
              />
            </Box>
          </Box>

          <Box className={styles.row}>
            <Box className={styles.column}>
              <Typography variant="body2">
                <Trans t={t} i18nKey="legal.profile">
                  I accept the{' '}
                  <Link
                    className={styles.link}
                    href={`${process.env.PUBLIC_URL}/docs/terms-and-conditions.${languageKey()}.pdf`}
                    target="_blank"
                  >
                    service conditions
                  </Link>{' '}
                  and the
                  <Link className={styles.link} href={`${process.env.PUBLIC_URL}/docs/privacy-policy.${languageKey()}.pdf`} target="_blank">
                    privacy policy
                  </Link>
                </Trans>
              </Typography>
            </Box>
          </Box>

          <Box className={styles.row}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={Object.keys(errors).length !== 0}
              variant="contained"
              color="primary"
              className={styles.confirm}
            >
              {userStore.saving && <CircularProgress size={16} className={styles.progress} />}
              {!userStore.saving && <Trans t={t} i18nKey='profile.actions.confirm' />}
            </Button>
          </Box>

        </Box>
      </Box>
    </Box>
  );
};

export default observer(Profile);
