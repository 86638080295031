export enum DocumentStatus {
  Pending = 'PENDING',
  Error = 'ERROR',
  Verified = 'VERIFIED',
  NotSigned = 'NOT_SIGNED'
}
export interface IDocument {
  id: number;
  filename: string;
  status: DocumentStatus;
  size?: number;
  content?: string;
  verifiedAt?: Date;
  validation?: IValidation;
  signature?: string;
}

export interface IValidation {
  isContentValid: boolean;
  isExpiredValid: boolean;
  isRevokedValid: boolean;
  isTrustedValid: boolean;
  isNotaryValid: boolean;
}
