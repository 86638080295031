import { makeStyles, Theme } from '@material-ui/core/styles';

type PopoperFilterStylesProps = {
  open: boolean;
};

const useStyles = makeStyles<Theme, PopoperFilterStylesProps>((theme) => ({
  item: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid #FFFFFF',
    color: '#FFFFFF',
    cursor: 'pointer',
    backgroundColor: ({ open }) => open ? '#0D4073' : '#737373'
  }
}));

export default useStyles;
