import React from 'react';
import useLoadingStyles from './Loading.styles';
import { Box, CircularProgress } from '@material-ui/core';

const Loading = () => {
  const classes = useLoadingStyles();

  return (
    <Box className={classes.root}>
      <Box className="wrapper">
        <CircularProgress size={50} />
      </Box>
    </Box>
  );
};

export default Loading;
