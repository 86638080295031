import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#333333',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filename: {
    color: '#FFFFFF',
    fontWeight: 600,
    flex: 1,
    marginLeft: theme.spacing(2)
  },
  content: {
    backgroundColor: '#52555B',
    padding: theme.spacing(5),
    paddingBottom: 0
  },
  file: {
    width: '100%',
    height: '360px'
  }
}));

export default useStyles;
