import React, { useEffect } from 'react';
import useStores from '../../../../hooks/useStores';
import { observer } from 'mobx-react';
import { Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './ConfirmCode.styles';
import { Controller, useForm } from 'react-hook-form';

const ConfirmCode = () => {
  const { onboardingStore, userStore } = useStores();
  const styles = useStyles();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  const onSubmit = (data: { code: string }) => {
    onboardingStore.confirm(data.code);
  };

  const onBack = () => {
    onboardingStore.back();
  };

  useEffect(() => {
    trigger();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={styles.container}>
      <Typography className={styles.info} variant="body2">
        <Trans t={t} i18nKey="onboarding.verify.hint" /> {userStore.user?.email}
      </Typography>
      <Box className={styles.box}>
        <Box className={styles.item}>
          <Typography className={styles.label} variant="body2">
            <Trans t={t} i18nKey="onboarding.verify.label" />
          </Typography>
          <Controller
            name="code"
            control={control}
            defaultValue={''}
            rules={{ required: true, minLength: 6, maxLength: 6 }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                className={styles.text}
                placeholder={t('onboarding.verify.placeholder')}
                autoComplete="disabled"
                size="small"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
              />
            )}
          />
          <Box className={styles.resend} onClick={() => onboardingStore.resend()}>
            {onboardingStore.resending && <CircularProgress size={16} className={styles.progress} />}
            {!onboardingStore.resending && (
              <Typography variant="h6">
                <Trans t={t} i18nKey="onboarding.verify.resend" />
              </Typography>
            )}
          </Box>
        </Box>

        <Box className={styles.actions}>
          <Button onClick={onBack} className={styles.back} variant="outlined" color="secondary">
            <Trans t={t} i18nKey="onboarding.actions.back" />
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={Object.keys(errors).length !== 0}
            className={styles.send}
            variant="contained"
            color="primary"
          >
            {onboardingStore.verifying && <CircularProgress size={16} className={styles.confirmProgress} />}
            {!onboardingStore.verifying && <Trans t={t} i18nKey={'onboarding.actions.confirm'} />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(ConfirmCode);
