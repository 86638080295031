import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  back: {
    textTransform: 'none',
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
    fontWeight: 600
  }
}));

export default useStyles;
