import { IExchange } from '../domain/Exchange';

export const getTitle = (exchange: IExchange, translate: any) => {
  let preffix = '';
  if (exchange.type) {
    preffix = translate('exchange.type.options.' + exchange.type.toLowerCase()) + ' - ';
  }

  let title = preffix + (exchange.subject ?? '----');
  return title.toUpperCase();
};
