import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    border: '1px solid #CCCCCC',
    marginBottom: theme.spacing(1),
    backgroundColor: '#FFFFFF',
    marginRight: theme.spacing(2),
    float:'left'
  },
  icon: {
    padding: theme.spacing(0, 1)
  },
  filename: {
    color: '#090909',
    flex: 1,
    marginLeft: theme.spacing(3)
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer'
  }
}));

export default useStyles;
