import React from 'react';
import { IDocument } from '../../../../domain/Document';
import { Box, Typography } from '@material-ui/core';
import useStyles from './AddedDocumentListItem.styles';
import useStores from '../../../../hooks/useStores';
import { observer } from 'mobx-react';

interface AddedDocumentListItemProps {
  document: IDocument;
}

const AddedDocumentListItem = ({ document }: AddedDocumentListItemProps) => {
  const styles = useStyles();
  const { exchangeStore } = useStores();
  const filename = () => {
    if (document.signature) {
      return document.filename + ' (pkcs7)';
    }

    return document.filename;
  };

  return (
    <Box className={styles.container}>
      <img src={`${process.env.PUBLIC_URL}/icons/ic_pdf.svg`} alt="" />
      <Typography className={styles.filename} variant="h6">
        {filename()}
      </Typography>
      <Box className={styles.actions} onClick={() => exchangeStore.removeDocument(document)}>
        <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/ic_close.svg`} alt="" />
      </Box>
    </Box>
  );
};

export default observer(AddedDocumentListItem);
