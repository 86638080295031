import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IDocument } from '../../../../domain/Document';
import useStores from '../../../../hooks/useStores';
import useStyles from './DocumentListItem.styles';
import { color, icon, text } from '../../../../services/documentService';

interface DocumentListItemProps {
  document: IDocument;
}

const DocumentListItem = ({ document }: DocumentListItemProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { exchangeStore, documentDetailStore } = useStores();
  const [validating, setValidating] = useState(false);

  const validate = () => {
    setValidating(true);
    exchangeStore.validateDocument(document);
  };

  useEffect(() => {
    if (document.validation && validating) {
      setValidating(false);
      documentDetailStore.viewDocument(document);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validating, document.validation]);

  return (
    <Box className={styles.container}>
      <img className={styles.icon} src={`${process.env.PUBLIC_URL}/icons/${icon(document)}.svg`} alt="" />
      <Box className={styles.data}>
        <Typography variant="body2">{document.filename}</Typography>
        <Box className={styles.status} style={{ backgroundColor: color(document) }}>
          <Typography className={styles.statusText}>
            <Trans t={t} i18nKey={text(document)} />
          </Typography>
        </Box>
      </Box>
      <Box className={styles.actions}>
        <Button onClick={() => exchangeStore.removeDocument(document)} className={styles.delete} variant="contained">
          <Trans t={t} i18nKey="confirm.actions.delete" />
        </Button>
        {!document.validation && (
          <Button onClick={validate} className={styles.view} variant="contained" color="primary">
            {validating && <CircularProgress size={16} className={styles.progress} />}
            {!validating && <Trans t={t} i18nKey="confirm.actions.check" />}
          </Button>
        )}
        {document.validation && (
          <Button onClick={() => documentDetailStore.viewDocument(document)} className={styles.view} variant="contained" color="secondary">
            <Trans t={t} i18nKey="confirm.actions.view" />
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default observer(DocumentListItem);
