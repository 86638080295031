import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Filter, Loading, Message, PagedList } from '../../components';
import useStores from '../../hooks/useStores';
import { VideoconferenceItem } from './components';
import { IVideoconference } from '../../domain/Videoconference';
import _ from 'lodash';
import moment from 'moment';

const Videoconferences = () => {
  const { videoconferenceStore, userStore } = useStores();
  const [data, setData] = useState<IVideoconference[]>();

  const onFilterChange = (filter: any) => {
    if (!filter) {
      setData(videoconferenceStore.videoconferences);
      return;
    }

    let result = videoconferenceStore.videoconferences!;
    if (filter.notary?.value) {
      const filterValue = filter.notary.value.toLowerCase();
      const predicate = (x: IVideoconference) => x.recipients.find((n) => n.notary.name?.toLowerCase().includes(filterValue));

      result = _.filter(result, predicate) as IVideoconference[];
    }

    if (filter.status) {
      result = _.filter(result, (x) => x.status === filter.status.value);
    }

    if (filter.period) {
      const from = moment(filter.period.value.from).subtract(1, 'days');
      const to = moment(filter.period.value.to).add(1, 'days');
      result = _.filter(result, (x) => moment(x.scheduledAt).isBetween(from, to, 'day'));
    }

    setData(result);
  };

  useEffect(() => {
    userStore.badges();
    videoconferenceStore.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (videoconferenceStore.loaded && videoconferenceStore.videoconferences) {
      setData(videoconferenceStore.videoconferences);
    }
  }, [videoconferenceStore.loaded, videoconferenceStore.videoconferences]);

  if (!videoconferenceStore.loaded) {
    return <Loading />;
  }

  if (videoconferenceStore.videoconferences?.length === 0) {
    return (
      <Box>
        <Message logo="camera" title="videoconferences.empty" subtitle="videoconferences.hint" />
      </Box>
    );
  }

  return (
    <Box>
      <Filter filterType="videos" onFilterChange={onFilterChange} />
      <PagedList data={data} render={(item: IVideoconference) => <VideoconferenceItem key={item.id} videoconference={item} />} />
    </Box>
  );
};

export default observer(Videoconferences);
