import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './Feedback.styles';
import useStores from '../../hooks/useStores';
import { observer } from 'mobx-react';

interface FeedbackProps {
  id: number;
  type: 'video' | 'exchange' | 'recipient';
}

const Feedback = ({ id, type }: FeedbackProps) => {
  const { feedbackStore } = useStores();
  const { t } = useTranslation();
  const styles = useStyles();

  useEffect(() => {
    feedbackStore.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={styles.container}>
      <Typography variant="body2" className={styles.title}>
        <Trans t={t} i18nKey="feedback.text" />
      </Typography>
      <Box className={styles.rates}>
        <Rate id={id} type={type} rate="very_low" />
        <Rate id={id} type={type} rate="low" />
        <Rate id={id} type={type} rate="acceptable" />
        <Rate id={id} type={type} rate="good" />
        <Rate id={id} type={type} rate="very_good" />
      </Box>
      {feedbackStore.result && (
        <Typography variant="body2" className={styles.result}>
          <Trans t={t} i18nKey="feedback.result" />
        </Typography>
      )}
    </Box>
  );
};

interface RateProps {
  id: number;
  type: 'video' | 'exchange' | 'recipient';
  rate: 'very_low' | 'low' | 'acceptable' | 'good' | 'very_good';
}

const Rate = ({ id, type, rate }: RateProps) => {
  const [filename, setFilename] = useState(`ic_${rate}.svg`);
  const [selected, setSelected] = useState(false);

  const styles = useStyles();
  const { feedbackStore } = useStores();

  const handleRateSelected = () => {
    setSelected(true);

    setFilename(`ic_${rate}_selected.svg`);
    feedbackStore.save(type, id, rate.toUpperCase());
  };

  if (selected) {
    return <img alt="" className={styles.rate} src={`${process.env.PUBLIC_URL}/icons/feedback/${filename}`} />;
  }

  return (
    <img
      alt=""
      className={styles.rate}
      src={`${process.env.PUBLIC_URL}/icons/feedback/${filename}`}
      onMouseOver={() => setFilename(`ic_${rate}_selected.svg`)}
      onMouseOut={() => setFilename(`ic_${rate}.svg`)}
      onClick={handleRateSelected}
    />
  );
};

export default observer(Feedback);
