import { Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ExchangeStatusBadge, Loading } from '../../../../components';
import { ExchangeStatus, IExchange } from '../../../../domain/Exchange';
import useStores from '../../../../hooks/useStores';
import useStyles from './Header.styles';
import { getTitle } from '../../../../services/exchangeService';

interface HeaderProps {
  exchange: IExchange;
}

const Header = ({ exchange }: HeaderProps) => {
  const { exchangeDetailStore, userStore } = useStores();
  const { t } = useTranslation();
  const styles = useStyles();

  if (!userStore.user) {
    return <Loading />;
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.logo}>
        <img alt="" src={`${process.env.PUBLIC_URL}/icons/exchange/${exchange.type?.toLowerCase() ?? 'document'}.svg`} />
      </Box>
      <Box className={styles.information}>
        <Typography className={styles.subjectTitle} variant="h3">
          {getTitle(exchange, t)}
        </Typography>
        <Box className={styles.status}>
          <ExchangeStatusBadge exchange={exchange} />
          <Typography variant="body2">{moment.utc(exchange.sentAt).local().format('DD/MM/yyyy HH:mm')}</Typography>
        </Box>
      </Box>
      <Box className={styles.notaries}>
        {exchange.from.notaryId !== userStore.user.id && (
          <Box className={styles.notary}>
            <Typography variant="h4" className={styles.notaryText}>
              {exchange.from.name.toLowerCase()}
            </Typography>
            <Typography variant="body2" className={styles.notaryLocation}>{`${exchange.from.city}, ${t(
              'countries.' + exchange.from.country.toLowerCase()
            )}`}</Typography>
          </Box>
        )}
        {exchange.from.notaryId === userStore.user.id &&
          exchange.recipients.map((recipient) => (
            <Box key={recipient.notary.notaryId} className={styles.notary}>
              <Typography variant="h4" className={styles.notaryText}>
                {recipient.notary.name.toLowerCase()}
              </Typography>
              <Typography variant="body2" className={styles.notaryLocation}>{`${recipient.notary.city}, ${t(
                'countries.' + recipient.notary.country.toLowerCase()
              )}`}</Typography>
            </Box>
          ))}
        {exchange.status !== ExchangeStatus.Archived && (
          <Box className={styles.actions}>
            <Button
              onClick={() => exchangeDetailStore.downloadAll(exchange.id)}
              className={styles.download}
              variant="contained"
              color="primary"
            >
              {!exchangeDetailStore.downloading && <Trans t={t} i18nKey="exchanges.actions.download" />}
              {exchangeDetailStore.downloading && <CircularProgress size={16} className={styles.progress} />}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default observer(Header);
