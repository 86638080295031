import React, { useEffect, useImperativeHandle, useRef } from 'react';
import useStores from '../../hooks/useStores';
import { Box, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { INotary, ISearchNotary } from '../../domain/Notary';
import { NotaryListItem, Search } from './components';
import Loading from '../loading/Loading';
import Message from '../message/Message';
import Paged, { PagedHandle } from '../paged/Paged';

interface SearchNotariesProps {
  hintKey: string;
  current?: INotary[];
  showTitle: boolean;
  showSearch: boolean;
  onSelect: (selectedNotary: INotary) => void;
}

const SearchNotaries = React.forwardRef<PagedHandle, SearchNotariesProps>((props, ref) => {
  const { hintKey, current, showTitle, showSearch, onSelect } = props;
  const { t } = useTranslation();
  const { notaryDirectoryStore } = useStores();
  const pagedRef = useRef<PagedHandle>(null);

  const handleFetch = (page: number, callback: (data: any[]) => void) => {
    notaryDirectoryStore.search(page, callback);
  };

  const handleSearch = (data: ISearchNotary) => {
    notaryDirectoryStore.configure(data);
    pagedRef.current?.search();
  };

  const handleExternalSearch = () => {
    pagedRef.current?.search();
  };

  useImperativeHandle(ref, () => ({
    search: handleExternalSearch
  }));

  useEffect(() => {
    return () => notaryDirectoryStore.clear();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showSearch && <Search onSearch={handleSearch} />}
      {!notaryDirectoryStore.initialized && <Message logo="search" title="search.title" subtitle={hintKey} />}
      <Box>
        {notaryDirectoryStore.initialized && notaryDirectoryStore.isEmpty && (
          <Message logo="search" title="search.empty.title" subtitle="search.empty.hint" />
        )}
        <Box mt={3}>
          {showTitle && !notaryDirectoryStore.isEmpty && (
            <Typography variant="h2">
              <Trans t={t} i18nKey="search.notaries" />
            </Typography>
          )}
          {notaryDirectoryStore.isLoading && <Loading />}
          <Box mt={2}>
            <Paged
              ref={pagedRef}
              mode="notaries"
              size={notaryDirectoryStore.size}
              total={notaryDirectoryStore.total ?? 0}
              fetch={handleFetch}
              render={(item: INotary) => <NotaryListItem key={item.notaryId} notary={item} current={current} onSelect={onSelect} />}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default observer(SearchNotaries);
