import React from 'react';
import { IExchange } from '../../domain/Exchange';
import { useHistory } from 'react-router';
import ExchangeListItem from '../exchangeListItem/ExchangeListItem';
import useStyles from './CompletedExchangeListItem.styles';
import { Button } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

interface CompletedExchangeListItemProps {
  exchange: IExchange;
  mode: 'received' | 'sent';
}

const CompletedExchangeListItem = ({ exchange, mode }: CompletedExchangeListItemProps) => {
  const history = useHistory();
  const styles = useStyles();
  const { t } = useTranslation();

  const onDetails = () => {
    const location = { pathname: `/private/${mode}/details/${exchange.id}`, state: { breadcrumb: exchange.subject.toUpperCase() } };
    history.push(location);
  };

  return (
    <ExchangeListItem exchange={exchange} mode={mode}>
      <Button onClick={onDetails} className={styles.navigate} variant="contained" color="primary">
        <Trans t={t} i18nKey="exchanges.actions.navigate" />
      </Button>
    </ExchangeListItem>
  );
};

export default CompletedExchangeListItem;
