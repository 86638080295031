import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    border: '1px solid #C9D9E8',
    marginTop: theme.spacing(2)
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  filename: {
    color: '#354052',
    fontWeight: 600,
    flex: 1
  },
  container: {
    padding: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  actions: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  back: {
    textTransform: 'none',
    backgroundColor: '#999999',
    color: '#FFFFFF',
    fontWeight: 600
  },
  data: {
    flex: 1,
    marginLeft: theme.spacing(3)
  },
  status: {
    borderRadius: 10,
    color: '#FFFFFF',
    padding: theme.spacing(0, 3),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px'
  },
  statusText: {
    fontSize: 11,
    fontWeight: 600
  },
  buttons: {},
  delete: {
    textTransform: 'none',
    backgroundColor: '#999999',
    color: '#FFFFFF',
    fontWeight: 600,
    marginRight: theme.spacing(2)
  },
  action: {
    textTransform: 'none',
    fontWeight: 600
  },
  drop: {
    border: `2px dashed ${theme.palette.text.primary}`,
    margin: theme.spacing(3),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default useStyles;
