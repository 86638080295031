import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { authInterceptor } from './interceptors/auth.interceptor';
import { errorInterceptor } from './errorInterceptor';

const baseUrl = process.env.REACT_APP_API_USE_ENVIRONMENT === 'true' ? process.env.REACT_APP_BASE_API_URL : '/eudoc/rest';

export const defaultConfig: { headers: any; timeout: number; responseType: ResponseType } = {
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 120000,
  responseType: 'json'
};

if (process.env.REACT_APP_ADD_CERT_HEADER === 'true' && process.env.REACT_APP_CERT_HEADER_NAME) {
  defaultConfig.headers[process.env.REACT_APP_CERT_HEADER_NAME] = process.env.REACT_APP_CERT_DATA;
}

export const axiosInstance = axios.create({ baseURL: baseUrl });
axiosInstance.interceptors.request.use(authInterceptor);
axiosInstance.interceptors.response.use((response) => response, errorInterceptor);

export const get = async <T>(endPoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  if (config?.headers) {
    config.headers = { ...defaultConfig.headers, ...config?.headers };
  }

  return axiosInstance.get(endPoint, { ...defaultConfig, ...config });
};

export const post = async <D, T>(endPoint: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  if (config?.headers) {
    config.headers = { ...defaultConfig.headers, ...config?.headers };
  }

  return axiosInstance.post(endPoint, data, { ...defaultConfig, ...config });
};

export const put = async <D, T>(endPoint: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return axiosInstance.put(endPoint, data, { ...defaultConfig, ...config });
};

export const remove = async <T>(endPoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return axiosInstance.delete(endPoint, { ...defaultConfig, ...config });
};

export const download = async (endPoint: string, filename: string) => {
  const config = { ...defaultConfig };
  config.responseType = 'blob';

  return axiosInstance.get(endPoint, { ...config }).then((response) => {
    let a = document.createElement('a');
    a.href = (window.webkitURL || window.URL).createObjectURL(response.data);
    a.download = filename;
    a.click();
  });
};
