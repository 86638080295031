import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { SelectNotaries, SelectDocuments, ConfirmExchange, Result, ViewDocument } from './components';
import { observer } from 'mobx-react';
import useStores from '../../hooks/useStores';
import { useLocation } from 'react-router';
import { Progress } from '../../components';

interface SendNewLocation {
  id?: number;
}

const SendNew = () => {
  const { exchangeStore, documentDetailStore, notaryDirectoryStore } = useStores();
  const { state } = useLocation<SendNewLocation>();

  useEffect(() => {
    exchangeStore.start(state?.id);
    documentDetailStore.clear();
    notaryDirectoryStore.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (documentDetailStore.document) {
    return <ViewDocument />;
  }

  return (
    <Box>
      {exchangeStore.index < 3 && <Progress options={exchangeStore.options} index={exchangeStore.index} />}
      {exchangeStore.index === 0 && <SelectNotaries />}
      {exchangeStore.index === 1 && <SelectDocuments />}
      {exchangeStore.index === 2 && <ConfirmExchange />}
      {exchangeStore.index === 3 && <Result />}
    </Box>
  );
};

export default observer(SendNew);
