import React from 'react';
import { Box, Button, MenuItem, TextField, Typography } from '@material-ui/core';
import useStyles from './Search.styles';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ISearchNotary } from '../../../../domain/Notary';

interface SearchProps {
  onSearch: (data: ISearchNotary) => void;
}

const Search = ({onSearch}: SearchProps) => {

  const styles = useStyles();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data: ISearchNotary) => {
    onSearch(data);
  };

  return (
    <Box className={styles.container}>
      <Box>
        <Typography className={styles.title} variant="h6">
          <Trans t={t} i18nKey="search.labels.country" />
        </Typography>
        <TextField
          className={styles.textField}
          variant="outlined"
          size="small"
          autoComplete="disabled"
          select={true}
          error={!!errors.country}
          {...register('country', { required: true })}
          defaultValue={'-'}
        >
          <MenuItem key="-" value="-" disabled={true} selected={true}>
            <Trans t={t} i18nKey="search.placeholders.country" />
          </MenuItem>
          <MenuItem key="es" value="ESP">
            <Trans t={t} i18nKey="countries.es" />
          </MenuItem>
          <MenuItem key="de" value="DEU">
            <Trans t={t} i18nKey="countries.de" />
          </MenuItem>
        </TextField>
      </Box>
      <Box className={styles.item}>
        <Typography className={styles.title} variant="h6">
          <Trans t={t} i18nKey="search.labels.city" />
        </Typography>
        <TextField
          className={styles.textField}
          variant="outlined"
          placeholder={t('search.placeholders.city')}
          size="small"
          autoComplete="disabled"
          {...register('city')}
        />
      </Box>
      <Box className={styles.item}>
        <Typography className={styles.title} variant="h6">
          <Trans t={t} i18nKey="search.labels.name" />
        </Typography>
        <TextField
          className={styles.textField}
          variant="outlined"
          placeholder={t('search.placeholders.name')}
          size="small"
          autoComplete="disabled"
          {...register('lastname')}
        />
      </Box>
      <Box className={styles.item}>
        <Typography className={styles.title} variant="h6">
          &nbsp;
        </Typography>
        <Button className={styles.button} variant="contained" size="medium" color="secondary" onClick={handleSubmit(onSubmit)}>
          <Trans t={t} i18nKey="search.action" />
        </Button>
      </Box>
    </Box>
  );
};

export default Search;
