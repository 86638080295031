import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  notFound: {
    textTransform: 'none',
    color: theme.exchange?.header.color,
    borderColor: theme.exchange?.header.color,
    margin: theme.spacing(3, 0),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.exchange?.header.color
    }
  }
}));

export default useStyles;
