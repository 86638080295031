import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.separator}`
  },
  status: {
    width: '30px',
    verticalAlign: 'top'
  },
  unread: {
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    backgroundColor: theme.palette.primary.main
  },
  content: {},
  contentTitle: {
    fontWeight: 600
  },
  notificationsTitles: {
    fontWeight: 600
  },
  notificationsDate: {
    marginTop: theme.spacing(0.5),
  },
  notaries: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  notary: {
    marginLeft: theme.spacing(3)
  },
  notaryText: {
    fontWeight: 600,
    textAlign: 'right',
    textTransform: 'capitalize'
  },
  notaryLocation: {
    marginTop: theme.spacing(0.5),
    textAlign: 'right'
  },
  actions: {
    marginLeft: theme.spacing(3)
  },
  open: {
    fontWeight: 600,
    textTransform: 'none',
    width: '138px',
    height: '40px',
    padding: '8px'
  }
}));

export default useStyles;
