import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  item: {
    width: '150px',
    paddingLeft: theme.spacing(2),
    backgroundColor: '#0D4073',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    alignItems: 'center',
    color: '#FFFFFF',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px'
  },
  input: {
    backgroundColor: '#FFFFFF',
    fontSize: 13,
    height: '100%',
    width: '240px',
    color: '#090909',
    '& ::placeholder': {
      color: '#ABABAB'
    }
  },
  icon: {
    cursor: 'pointer'
  }
}));

export default useStyles;
